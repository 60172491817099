import { useEffect, useState } from "react";
import { BASE_PAGE } from "../../utils/constant";
import api from "../../api";
import { FunctionGetPathUrl } from "../../utils/useFunction";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import FunctionHome from "../../utils/home";
import YouTube from "react-youtube";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import classNames from "classnames";

const checkIsActiveVideo = (name, list) => {
  const result = list?.includes(name);

  return result;
};

const Lesson = () => {
  const handle = useFullScreenHandle();
  // <div className="w-full h-11 bg-blue-700 absolute bottom-0 flex ">
  //   <img src="images/logodms.png" className="w-11 h-11 mx-8 "></img>
  //   <div className=" tsext-white text-xl mt-2">สถาบันเวชศาสตร์สมเด็จพระสังฆราชญาณสังวรเพื่อผู้สูงอายุ กรมการแพทย์ กระทรวงสาธารณสุข</div>
  // </div>

  //   const mock = [
  //     { name: "1", dok: true },
  //     { name: "2", dok: false },
  //     { name: "3", dok: true },
  //     { name: "4", dok: true },
  //     { name: "5", dok: false },
  //     { name: "6", dok: false },
  //   ];

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [iframe, setIframe] = useState(null);

  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = SystemStoreUser((state) => state.user);
  const setUser = SystemStoreUser((state) => state.setUser);

  // const [finised, setFinised] = useState(false);

  // console.log(`user; `, user);

  // console.log("lesson", lessons);

  // const handleIframeLoad = () => {
  //   const iframe = document?.getElementById("iframe_youtube");
  //   setIframe(iframe);
  // };

  useEffect(() => {
    loadLessons();
  }, []);

  // useEffect(() => {
  //   try {
  //     const iframe = document.getElementById("iframe_youtube");
  //     iframe.addEventListener("load", handleIframeLoad);
  //     return () => {
  //       iframe.removeEventListener("load", handleIframeLoad);
  //     };
  //   } catch (error) {
  //     alert(error);
  //   }
  // }, [iframe]);

  // useEffect(() => {
  //   try {
  //     if (iframe) {
  //       console.log("1", new window.YT());
  //       const player = new window.YT.Player(iframe, {
  //         events: {
  //           onStateChange: (e) => {
  //             console.log("e", e);
  //           },
  //         },
  //       });
  //       return () => {
  //         player.destroy();
  //       };
  //     }
  //   } catch (error) {
  //     alert(error);
  //   }
  // }, [iframe]);

  const loadLessons = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/lesson");
      setLessons(responseData?.lessons);
    } catch (error) {
      console.log("ERROR TO LOAD LESSON :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/users/${user?._id}`);
      setUser(data?.user);
    } catch (error) {
      console.log("ERROR TO LOAD Profile :", error);
    } finally {
      setLoading(false);
    }
  };

  const videoOnEnd = async () => {
    try {
      console.log("1");
      const prevvideolist =
        user?.video_list?.length > 0
          ? user?.video_list?.filter((videoItem) => {
              return videoItem !== lessons[currentIndex].lesson_name;
            })
          : [];
      await api.put("/users/" + user._id, {
        video_list: [...prevvideolist, lessons[currentIndex].lesson_name],
      });
      await loadProfile();
    } catch (error) {}
  };

  useEffect(() => {
    setTitle("บทเรียนที่ ");
  }, []);
  const data = [
    {
      name: "บทเรียนที่ 1สถานการณ์ผู้สูงอายุไทยและนโยบายการจัดตั้งคลินิกผู้สูงอายุ",
      dok: "ไม่มี",
    },
    { name: "บทเรียนที่ 2 เกณฑ์มาตรฐานการจัดตั้งคลินิกผู้สูงอายุ", dok: "มี" },
    {
      name: "บทเรียนที่ 3 การประเมินสุขภาพผู้สูงอายุ (Comprehensivegeriatric assessment-CGA) โดยบุคลากรทาง การแพทย์",
      dok: "มี",
    },
    {
      name: "บทเรียนที่ 4 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Dementiaand Mild cognitive impairment",
      dok: "มี",
    },
    {
      name: "บทเรียนที่ 5 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่องDepression",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 6 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Insomnia",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 7 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่องFalling/Instability and OA",
      dok: "มี",
    },
    {
      name: "บทเรียนที่ 8 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่องNutrition",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 9 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่องสุขภาพช่องปาก",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 10 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Eye",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 11 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่อง ENT",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 12 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่องIncontinence",
      dok: "ไม่มี",
    },
    {
      name: "บทเรียนที่ 13 หลักการใช้ยาและการจัดการปัญหา polypharmacyในผู้สูงอายุ",
      dok: "ไม่มี",
    },
    { name: "บทเรียนที่ 14 Geriatric ambulatory Emergency", dok: "ไม่มี" },
    {
      name: "บทเรียนที่ 15 อภิปรายเรื่องประสบการณ์การจัดตั้งคลินิกผู้สูงอายุ",
      dok: "ไม่มี",
    },
    // { name: "บทเรียนที่ 16", dok: "ไม่มี" },
  ];

  const onClickFullScreen = () => {
    // const iframe = document.getElementById("iframe_youtube");
    // if (iframe) {
    //   if (iframe.requestFullscreen) {
    //     iframe.requestFullscreen();
    //   } else if (iframe.mozRequestFullScreen) {
    //     /* Firefox */
    //     iframe.mozRequestFullScreen();
    //   } else if (iframe.webkitRequestFullscreen) {
    //     /* Chrome, Safari and Opera */
    //     iframe.webkitRequestFullscreen();
    //   } else if (iframe.msRequestFullscreen) {
    //     /* IE/Edge */
    //     iframe.msRequestFullscreen();
    //   }
    // }
  };

  const showCT = [0, 4, 5, 7, 8, 9, 10, 11, 12, 13, 14, 15].includes(
    currentIndex
  );

  // let videoProps = { autoPlay: true };
  // if (showCT) {
  //   videoProps = {
  //     controls: true,
  //   };
  // }

  return (
    <div
      style={{
        backgroundPosition: "center",

        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // marginBottom: "-60px",
      }}
      className="bg-white"
    >
      <FunctionHome />
      <div className="p-6 md:p-6 lg:p-10 xl:p-10 block md:flex justify-between gap-4 -mt-10 md:-mt-20 ">
        <div
          className="bg-dms-2navbar  border-2 border-dms-2purplelight w-full md:w-4/12 lg:w-5/12 xl:w-5/12 h-[35vh] md:h-[60vh] lg:h-[80vh] xl:h-[80vh] 
        overflow-y-scroll cursor-pointer  md:mt-10"
        >
          <div className=" text-2xl md:text-base lg:text-2xl xl:text-2xl  text-dms-2textblue font-Kanit font-medium  grid gap-y-4">
            {data?.map((row, index) => {
              const active = checkIsActiveVideo(row.name, user?.video_list);

              return (
                <div
                  onClick={() => {
                    setTitle(row?.name);
                    setCurrentIndex(index);
                  }}
                  className=" grid grid-cols-12 gap-2 items-center hover:bg-dms-2purpleintensive hover:text-white rounded w-full  p-2"
                >
                  <div className="col-span-2">
                    {active ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className=" w-10 text-green-500"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7t.275.7L9.9 15.9q.275.275.7.275t.7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275t-.7.275L10.6 13.8ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-10 md:w-6 lg:w-10 xl:w-10 "
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="m10.6 13.8l-2.175-2.175q-.275-.275-.688-.263t-.687.288q-.275.275-.275.7t.275.7L9.9 15.9q.275.275.7.275t.7-.275l5.675-5.675q.275-.275.263-.688t-.288-.687q-.275-.275-.7-.275t-.7.275L10.6 13.8ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-10Zm0 8q3.325 0 5.663-2.337T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.337T4 12q0 3.325 2.337 5.663T12 20Z"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="col-span-10">
                    <div className=" truncate  ">{row?.name}</div>
                    <span className="text-red-500">
                      {" "}
                      {row?.dok === "มี" ? "* (บังคับ)" : ""}{" "}
                    </span>
                  </div>
                </div>
              );
            })}
            {/* {mock?.map((row) => {
              return <div> บทเรียนที่ {row?.name} </div>;
            })} */}
            {/* <div className="">บทเรียนที่ 1</div>
            <div className="">บทเรียนที่ 2</div>
            <div className="">บทเรียนที่ 3</div>
            <div className="">บทเรียนที่ 4</div>
            <div className="">บทเรียนที่ 5</div>
            <div className="">บทเรียนที่ 6</div>
            <div className="">บทเรียนที่ 7</div>
            <div className="">บทเรียนที่ 8</div>
            <div className="">บทเรียนที่ 9</div>
            <div className="">บทเรียนที่ 10</div>
            <div className="">บทเรียนที่ 11</div>
            <div className="">บทเรียนที่ 12</div>
            <div className="">บทเรียนที่ 13</div>
            <div className="">บทเรียนที่ 14</div>
            <div className="">บทเรียนที่ 15</div> */}
          </div>
        </div>
        <div className="text-dms-2textblue text-2xl md:text-3xl lg:text-4xl xl:text-3xl font-Kanit font-medium w-9/12 mt-4 md:mt-0">
          {" "}
          {title}{" "}
          <div
            className={`bg-white border-2 border-stone-500 w-80 md:w-full h-[20vh] md:h-[30vh] lg:h-[40vh] xl:h-[60vh] ${
              showCT ? "" : "disable-progress-bar"
            }`}
          >
            <FullScreen
              handle={handle}
              onChange={(reportChange) => {
                console.log("reportChange", reportChange);
              }}
            >
              <div class="youtube-container">
                <YouTube
                  className={classNames("h-full", {
                    "ml-72 w-72": !handle?.active,
                    // "ml-72": handle?.active,
                  })}
                  title=""
                  opts={{
                    width: "100%",
                    height: "100%",
                    playerVars: {
                      controls: lessons?.[currentIndex]?.isControl ? 0 : 1,
                      playsinline: 1,
                      muted: 1,
                      modestbranding: 0,
                      rel: 0,
                      enablejsapi: 1,
                      showinfo: 1,
                      autohide: 1,
                      // color: "white",
                    },
                  }}
                  iframeClassName="iframe"
                  videoId={lessons?.[currentIndex]?.video}
                  onStateChange={(e) => {
                    if (e?.data === 0) {
                      videoOnEnd();
                    }
                  }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                  allowFullScreen
                />
                {/* <iframe
                  id="iframe_youtube"
                  src={`${lessons?.[currentIndex]?.video}?controls=${
                    lessons?.[currentIndex]?.isControl ? 0 : 1
                  }&modestbranding=1&playsinline=1&rel=0&enablejsapi=1&showinfo=0`}
                  // title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                  allowFullScreen
                  onEnded={() => videoOnEnd()}
                ></iframe> */}
                {/* <div className=" absolute w-full h-9 z-50 bg-white bottom-0"></div> */}
                {/* <div className=" absolute w-full h-9 z-50 bg-white top-0"></div> */}
                {/* <div
                  onClick={() => {
                    if (!handle?.active) {
                      handle.enter();
                    } else {
                      handle.exit();
                    }
                  }}
                  className="  absolute w-6 z-50 bottom-0 left-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-black cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </div> */}
              </div>
            </FullScreen>

            {/* <YouTube
              opts={{
                playerVars: {
                  controls: 0,
                  playsinline: 1,
                  muted: 1,
                  modestbranding: 0,
                },
              }}
              videoId={lessons?.[currentIndex]?.video}
            /> */}
            {/* <video
              className="w-full h-full "
              onEnded={() => videoOnEnd()}
              // src={lessons?.[currentIndex]?.video}
              // src="https://www.youtube.com/watch?v=n-WbAWqZ7t4"
              controls
              playsinline
              muted
              type="video/mp4"
              // {...videoProps}
            /> */}

            <div className="text-sm mt-3 text-red-500">
              * แนะนำ : โปรแกรม (Browser) ที่แนะนำให้ใช้ในการรับชม ได้แก่ Google
              Chrome, Microsoft Edge , Firefox
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lesson;
