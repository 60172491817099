import { useNavigate } from "react-router-dom";

const FunctionHome = () => {
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const onClickHome = () => {
    // localStorage.clear();
    // dispatch(initAuth());
    navigate("/menu");
  };
  return (
    <div
      onClick={onClickHome}
      className="flex items-center cursor-pointer px-0 md:px-5 py-5 "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        className="w-6 md:w-6 lg:w-6 xl:w-6 h-6 md:h-6 lg:h-6 xl:h-6 mb-1 md:mb-0"
      >
        <path fill="#15496D" d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z" />
      </svg>
      <div className="flex cursor-pointer hover:text-dms-bluetext text-dms-2textblue  text-xl font-Kanit font-medium rounded-xl mx-2">
        หน้าหลัก
      </div>
    </div>
  );
};
export default FunctionHome;
