import { mock_up_exam, mock_up_score } from "../../utils/mockData";
// import { PaginationRounded } from "../Pagination";
import React, { useEffect, useState, useId } from "react";
import { BASE_PAGE, CornerLogo } from "../../utils/constant";
// import { Radar } from "react-chartjs-2";
import api from "../../api";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import FunctionLogOut from "../../utils/logout";
import FunctionHome from "../../utils/home";
import { SystemStoreExamData } from "../../systems/Systems.StorageExam";
import Certificate from "../pdf/Certificate";
import { saveAs } from "file-saver";
import { pdf, PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { FunctionGetPathUrl } from "../../utils/useFunction";

export const Button = ({
  text,
  onClick,
  className = " font-Mitr text-xs md:text-base max-w-xs p-1 md:p-0 w-full md:w-44 lg:w-48 h-full md:h-10 bg-white border-2 md:border-4 border-gray-500  duration-300 hover:shadow-lg  hover:border-none  hover:text-red-500 rounded-2xl mx-auto flex gap-2 justify-center items-center text-gray-500 font-medium  ",
  svg,
}) => {
  return (
    <div>
      <div>
        <button onClick={(e) => onClick(e)} className={className}>
          {text}
          {svg}
        </button>
      </div>
    </div>
  );
};

const Line = ({ percent }) => {
  <div className="mt-2 ml-10 sm:ml-80 h-4 relative w-60 rounded-full overflow-hidden">
    <div className=" w-full h-full bg-gray-200 absolute " />
    <div className=" absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 text-xs text-white ">
      4/5 ({percent}%)
    </div>
    <div
      className=" h-full bg-yellow-400  absolute z-10"
      style={{
        width: `${percent}%`,
      }}
    ></div>
  </div>;
};

export const FunctionMillisToMinutesAndSeconds = (millis) => {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);

  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

const Timer = ({ countTime = "00:00:00" }) => {
  return (
    <div className="flex justify-start px-10 pt-4">
      <div className=" text-red-700 font-DBAdmanX font-medium text-xl md:text-5xl text-center">
        {countTime}
      </div>
    </div>
  );
};

const BarComplete = ({ percent = 0, countChoice = 0, currentChoice = 0 }) => {
  const thisPercent = Math.round(percent).toString();
  return (
    <div className=" px-4 md:px-28 lg:px-48 xl:px-96">
      {/* <div className=" flex justify-between my-6 md:my-2 text-sm md:text-base lg:text-lg xl:text-base">
        <div className=" text-white ">{thisPercent}% Complete</div>
        <div className=" text-gray-500 ">
          {currentChoice} of {countChoice}
        </div>
      </div> */}
      <Line percent={thisPercent} />
    </div>
  );
};

const Yourscore = ({ setOpenAns, exam, point }) => {
  const countscore = () => {
    const score = exam.reduce((prev, curr) => {
      if (curr?.choose_answer === curr?.correct) {
        return prev + 1;
      }
      return prev;
    }, 0);
    return score;
  };

  const handleDownload = async () => {
    // let blob = await pdf(<Certificate data={data} />).toBlob();
    let blob = await pdf(<Certificate />).toBlob();
    saveAs(blob, "DMS.pdf");
  };

  const user = SystemStoreUser((state) => state?.user);
  console.log("user:", user);
  return (
    <div
      style={{
        backgroundPosition: "center",

        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "-60px",
      }}
      className=""
    >
      <FunctionHome />
      <div className=" font-bold w-full md:pt-28  lg:pt-20 xl:pt-20 text-dms-2textblue text-lg md:text-4xl lg:text-5xl xl:text-5xl   ">
        ระบบทดสอบการพัฒนาศักยภาพบุคลากร
        <div className=" font-bold w-full md:mt-2 lg:mt-2 xl:mt-2 text-dms-2textblue text-lg md:text-4xl lg:text-5xl xl:text-5xl ">
          เพื่อการจัดตั้งคลินิกผู้สูงอายุ
        </div>
      </div>
      <div className="">
        <div className="mt-32 md:mt-32 lg:mt-32 xl:mt-14 relative">
          <img
            src="images/logincard.png"
            className="rounded-2xl mx-auto md:h-60 lg:h-60 xl:h-48 md:w-8/12 lg:w-8/12 xl:w-5/12"
          />
          <div className="  font-Kanit font-medium  text-2xl  md:text-4xl lg:text-5xl xl:text-5xl text-dms-graytext absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            คะแนนของคุณ {point}/{exam?.length}
          </div>
        </div>
        {/* รอเปิด */}
        {/* {point <= 10 && (
          <div>
            <div className=" mt-5 text-2xl md:text-4xl lg:text-5xl xl:text-4xl text-red-700">
              ไม่ผ่านการอบรม
            </div>
          </div>
        )}
        {point >= 10 && (
          <div>
            <div className=" mt-5 text-2xl md:text-4xl lg:text-5xl xl:text-4xl text-green-700">
              ผ่านการอบรม
            </div>
            <PDFDownloadLink document={<Certificate user={user} />}>
              <div className=" ">
                <div
                  className="  font-DBAdmanX font-medium text-white md:text-2xl lg:text-2xl xl:text-3xl 
           bg-blue-600  hover:bg-blue-500 rounded-full h-14 px-10 mt-5 mb-5 w-72 mx-auto pt-3 cursor-pointer"
                >
                  ดาวน์โหลดประกาศนียบัตร
                </div>
              </div>
            </PDFDownloadLink>
          </div>
        )} */}
      </div>
      {/* <CornerLogo /> */}
    </div>
  );
};

export const TestExam = ({
  finish,
  setFinish,
  exam,
  setExam,
  point,
  setPoint,
}) => {
  const [countTime, setCountTime] = useState(1800000);

  // test
  // const [countTime, setCountTime] = useState(15000);

  const [currentChoice, setCurrentChoice] = useState(0);
  const [loading, setLoading] = useState(true);

  const onChangecurrentChoice = (action) => {
    if (action === "ย้อนกลับ") {
      if (currentChoice === 0) {
        return;
      }
      setCurrentChoice(currentChoice - 1);
    }
    if (action === "ต่อไป") {
      if (currentChoice === 20) return;
      setCurrentChoice(currentChoice + 1);
    }
  };

  const selectedCount =
    exam?.filter((e) => {
      return e.selected === true;
    }).length || 0;

  const countChoice = exam.length || 0;

  const percent = (selectedCount / countChoice) * 100;

  // console.log("exam : ", exam);

  // const onSubmit = async (form) => {
  //   console.log("form : ", { form, loadData });
  //   if (loadData?._id) {
  //     try {
  //       await api.put(`/users/score${loadData._id}`, form);
  //     } catch (error) {
  //       console.error("ERROR TO UPDATE SCORE : ", error);
  //     }
  //   }
  //   }
  // };

  const user = SystemStoreUser((state) => state.user);

  const examData = SystemStoreExamData((state) => state.examData);
  const setExamData = SystemStoreExamData((state) => state.setExamData);
  const clearExamData = SystemStoreExamData((state) => state.clearExamData);

  // console.log(`examData: `, examData);
  // console.log("countTime:", countTime);
  // reroad page
  useEffect(() => {
    const onConfirmRefresh = function (event) {
      event.preventDefault();

      // console.log(`examData: `, examData);
      // console.log("countTime:", countTime);
      // setExamData({
      //   countTime: countTime,
      //   choose: event.choice,
      //   selected: event.selected,
      // });
    };

    window.addEventListener("beforeunload", onConfirmRefresh, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", onConfirmRefresh, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    if (countTime > 0) {
      setTimeout(() => {
        setCountTime(countTime - 1000);
      }, 1000);
    } else {
      const checkAfterTimeout = async () => {
        const temp = exam?.map((i) => {
          return {
            choice: i?.choose_answer,
          };
        });
        const { data } = await api.post("/exam/checkPoint", {
          selected: temp,
          _id: user?._id,
        });
        if (data) {
          setPoint(data?.point);
        }
        setCurrentChoice(0);
        setFinish(true);
      };

      checkAfterTimeout();

      return;
    }
  }, [countTime]);

  // useEffect(() => {
  //   loadExam();
  // }, []);

  // const loadExam = async () => {
  //   try {
  //     setLoading(true);
  //     const { data: responseData } = await api.get("/exam");
  //     setExam(responseData?.exam);
  //   } catch (error) {
  //     console.error("ERROR TO LOAD TEST :", error);
  //   } finally {
  //   }
  // };

  const onClickSelect = (event) => {
    // setCurrentChoice(currentChoice + 1);
    setExam((priv) => {
      let temp = [...priv];

      temp[currentChoice] = {
        ...temp[currentChoice],
        choose: event.choice,
        choose_answer: event.answer,
        selected: true,
      };

      return [...temp];
    });
  };

  // const onClickBack = (event) => {
  //   setCurrentChoice(currentChoice - 1);
  //   setExam((priv) => {
  //     let temp = [...priv];

  //     temp[currentChoice] = {
  //       ...temp[currentChoice],
  //       choose: event.choice,
  //       selected: true,
  //     };

  //     return [...temp];
  //   });
  // };

  // console.log("currentChoice", currentChoice);

  return (
    <div
      style={{
        backgroundPosition: "center",

        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "-60px",
      }}
      className={BASE_PAGE}
    >
      <Timer countTime={FunctionMillisToMinutesAndSeconds(countTime)} />
      <BarComplete
        percent={percent}
        countChoice={countChoice}
        currentChoice={selectedCount}
      />
      {/* <div className=" flex justify-center my-4 md:my-10  ">
        <PaginationRounded
          value={currentChoice + 1}
          count={countChoice}
          setValue={setCurrentChoice}
        />
      </div> */}
      <div
        className="flex justify-start font-Kanit font-medium  w-full py-4 md:py-4 lg:py-4 xl:py-16 
      md:px-16 lg:px-16 xl:px-16 text-dms-2textblue text-2xl md:text-4xl lg:text-5xl xl:text-5xl"
      >
        Pre-Test
      </div>
      <div className=" md:mx-16 lg:mx-full xl:mx-full  ">
        <div className="bg-white w-full h-auto p-4">
          <div className="flex ">
            <div className=" text-dms-2textblue  text-sm md:text-2xl lg:text-2xl xl:text-2xl font-Kanit font-medium  ">
              {currentChoice + 1}.
            </div>

            <div className=" text-left font-Kanit font-medium text-sm  md:text-2xl lg:text-2xl xl:text-2xl  text-dms-2textblue ">
              {exam[currentChoice]?.question?.type === "video" ? (
                <React.Fragment>
                  <video
                    controls
                    src={FunctionGetPathUrl(
                      exam[currentChoice]?.question?.question
                    )}
                  />
                  {exam[currentChoice]?.question?.text && (
                    <div>{exam[currentChoice]?.question?.text}</div>
                  )}
                </React.Fragment>
              ) : exam[currentChoice]?.question?.type === "image" ? (
                <div>
                  <img
                    src={FunctionGetPathUrl(
                      exam[currentChoice]?.question?.question
                    )}
                  />
                  {exam[currentChoice]?.question?.text && (
                    <div>{exam[currentChoice]?.question?.text}</div>
                  )}
                </div>
              ) : (
                <React.Fragment>
                  {exam[currentChoice]?.question?.question}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        {/* {exam?.question?.type === "text" ? (
                        <React.Fragment>
                          <div
                          className=" "
                          value={props?.value?.question}
                          // onChange={({ target: { value } }) => {
                          //   props?.setValue((prev) => {
                          //     let temp = { ...prev };
                          //     temp.question = value;
                          //     return {
                          //       ...temp,
                          //     };
                          //   });
                          // }}
                          >
                          </div>
                        </React.Fragment>
                      ) : props?.value?.type === "image" ? (
                        <React.Fragment>
                          
                        </React.Fragment>
                      ) : props?.value?.type === "video" ? (
                        <React.Fragment>
                          <div
                          className=" "
                          value={props?.value}
                          // onChange={({ target: { files } }) => {
                          //   if (files.length > 0) {
                          //     const file = files[0];
                          //     // const url = URL.createObjectURL(file);
                          //     props.setValue((prev) => {
                          //       let temp = { ...prev };
                          //       temp.question = file;
                          //       return {
                          //         ...temp,
                          //       };
                          //     });
                          //   }
                          // }}
                          >
                          </div>
                          {exam?.value?.question?.size ? (
                            <video
                              src={FunctionAddUrlToFile(exam?.question?.question)}
                              controls
                            />
                          ) : exam?.question?.question ? (
                            <video
                              src={FunctionGetPathUrl(exam?.question?.question)}
                              controls
                            />
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )} */}
        <div className="grid grid-cols-2 gap-2 md:gap-8 mt-10">
          {exam[currentChoice]?.choice?.map((c, i) => {
            const isSeleted = c.choice === exam[currentChoice]?.choose;
            return (
              <div
                key={i}
                onClick={() => onClickSelect(c)}
                className={` ${
                  isSeleted
                    ? ` bg-green-500 text-white cursor-pointer `
                    : ` bg-white hover:bg-dms-Choice hover:text-white  text-dms-2textblue font-medium `
                } cursor-pointer duration-300 w-full rounded-md h-full p-2 md:p-4  flex jut items-center   text-left text-xs md:text-xl lg:text-xl xl:text-xl  font-Kanit font-medium `}
              >
                <div className="flex">
                  <div
                    className={
                      isSeleted
                        ? "cursor-pointer font-DBAdmanX font-medium  w-10  text-center   md:text-3xl lg:text-3xl xl:text-3xl text-white"
                        : "cursor-pointer font-DBAdmanX font-medium  w-10  text-center   md:text-3xl lg:text-3xl xl:text-3xl text-dms-2textblue"
                    }
                  >
                    {c?.choice}.
                  </div>
                  <div className="mx-2 ">{c?.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-evenly md:px-40 lg:px-40 xl:px-60 mt-10 md:pb-4">
          {/* ดักเวลาอยู่ข้อ1 ไม่โชว์? ปุ่มย้อนกลับ */}
          {currentChoice !== 0 && (
            <div className="relative     cursor-pointer">
              <div className="">
                <img
                  src="/images/ปุ่มยืนยัน.png"
                  className="h-10 md:h-10 lg:h-12 xl:h-12 w-24 md:w-24 lg:w-36 xl:w-36 mb-10"
                />
                <div
                  onClick={() => onChangecurrentChoice("ย้อนกลับ")}
                  className="absolute font-Kanit font-medium left-4 md:left-8 lg:left-14 xl:left-6 mx-1 top-1 md:top-1 lg:top-1 xl:top-1 text-base  md:text-xl lg:text-2xl xl:text-2xl text-dms-2textblue"
                >
                  ย้อนกลับ
                </div>
              </div>
            </div>
          )}
          <div className="relative     cursor-pointer">
            <div className="">
              <img
                src="/images/ปุ่มยืนยัน.png"
                className="h-10 md:h-10 lg:h-12 xl:h-12 w-24 md:w-24 lg:w-36 xl:w-36 mb-10 "
              />
              <div
                onClick={async () => {
                  if (currentChoice < exam?.length - 1) {
                    setCurrentChoice(currentChoice + 1);
                  } else if (
                    currentChoice === exam?.length - 1 &&
                    exam?.length > 0
                  ) {
                    const temp = exam?.map((i) => {
                      return {
                        choice: i?.choose_answer,
                      };
                    });
                    const { data } = await api.post("/exam/checkPoint", {
                      selected: temp,
                      _id: user?._id,
                    });
                    if (data) {
                      setPoint(data?.point);
                    }
                    setCurrentChoice(0);
                    setFinish(true);
                  } else {
                    return;
                  }
                }}
                className="absolute font-Kanit font-medium left-8 md:left-8 lg:left-14 xl:left-14 mx-1 top-1 md:top-1 lg:top-1 xl:top-1 text-base  md:text-xl lg:text-2xl xl:text-2xl text-dms-2textblue "
              >
                ส่ง
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FinishExam = ({ exam, point }) => {
  const [openAns, setOpenAns] = useState(false);
  const [currentChoice, setCurrentChoice] = useState(0);

  return (
    <div>
      <Yourscore setOpenAns={setOpenAns} exam={exam} point={point} />
      {openAns && (
        <React.Fragment>
          <div className="w-full p-10">
            <div className=" text-blue-500 font-black text-xl md:text-2xl">
              {/* ชุดข้อสอบ 5 ข้อ */}
            </div>

            <div className="text-gray-500 font-bold text-base md:text-lg mt-4">
              No.{currentChoice + 1}
              <div className="text-xs md:text-base font-light mt-2">
                {exam?.question}
              </div>
            </div>

            {exam.awnser?.choice?.map((c, cIndex) => {
              const isSeleted = c.choice === exam[currentChoice]?.choose;
              const isCorrect = c.type === "correct";
              return (
                <div
                  key={cIndex}
                  className={` ${
                    isSeleted || isCorrect
                      ? [
                          isCorrect
                            ? " bg-green-600 text-white "
                            : " bg-red-500 text-white ",
                        ]
                      : ` bg-gray-500 text-gray-500 `
                  } duration-300 w-full rounded-md h-full p-2  flex items-center gap-6 text-base md:text-lg mt-4 `}
                >
                  <div className="   w-10 h-10 text-center py-2 md:py-1 text-gray-500">
                    {c?.choice}
                  </div>
                  {c?.answer}
                </div>
              );
            })}
          </div>

          {/* <div className=" flex justify-center my-4 md:my-10  ">
            <PaginationRounded
              count={exam.length}
              value={currentChoice + 1}
              setValue={setCurrentChoice}
            />
          </div> */}
        </React.Fragment>
      )}
    </div>
  );
};
