import axios from "axios";

// const dev = process.env.NODE_ENV === "development";

const instance = axios.create({
  // baseURL: "http://localhost:1337",
  baseURL: "https://4v4z1kfjaa.execute-api.ap-southeast-1.amazonaws.com/dev",
});

export default instance;
