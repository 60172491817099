import { Navigate, useLocation } from "react-router-dom";
import { CornerLogo } from "./constant";
import { useIsMobile } from "./ismobile";
import { useNavigate } from "react-router-dom";
import { SystemStoreUser } from "../systems/Systems.StorageUser";

const Navbar = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const clearUser = SystemStoreUser((state) => state.clearUser);
  const user = SystemStoreUser((state) => state.user);

  const clickLogOut = () => {
    if (!window.confirm("ยืนยันการออกจากระบบ")) return;

    clearUser();
    navigate("/login");
  };
  const isViewScore = location?.pathname === "/Viewscore";
  const isMenu = location?.pathname === "/menu";
  const isLesson = location?.pathname === "/lesson";
  const isFaq = location?.pathname === "/faq";

  const isActive =
    location?.pathname === "/" ||
    location?.pathname === "/login" ||
    location?.pathname === "/register";

  if (isActive) return null;
  // <div className="w-full h-11 bg-blue-700 absolute bottom-0 flex ">
  //   <img src="images/logodms.png" className="w-11 h-11 mx-8 "></img>
  //   <div className=" text-white text-xl mt-2">สถาบันเวชศาสตร์สมเด็จพระสังฆราชญาณสังวรเพื่อผู้สูงอายุ กรมการแพทย์ กระทรวงสาธารณสุข</div>
  // </div>
  return (
    <div className="bg-dms-2purpleintensive  w-full h-16 ">
      <div className=" ">
        <CornerLogo />
        <div
          className="grid grid-cols-4 text-white text-xs md:text-base lg:text-xl xl:text-xl text-center font-Kanit font-normal 
          mx-10 md:mx-32 lg:mx-36 xl:mx-96  h-16  "
        >
          <div
            onClick={() => navigate("/menu")}
            className={` ${
              isMenu
                ? " text-dms-2textblue bg-dms-2navbar "
                : " hover:text-dms-2textblue hover:bg-dms-2navbar "
            } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
          >
            หน้าแรก
          </div>
          {user?.pre_test ? (
            <div
              onClick={() => navigate("/lesson")}
              className={` ${
                isLesson
                  ? " text-dms-2textblue bg-dms-2navbar "
                  : " hover:text-dms-2textblue hover:bg-dms-2navbar "
              } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
            >
              เข้าสู่บทเรียน
            </div>
          ) : (
            <div
              className={` ${
                isLesson
                  ? " text-dms-2textblue bg-dms-2navbar "
                  : " hover:text-dms-2textblue hover:bg-dms-2navbar "
              } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
            >
              เข้าสู่บทเรียน
            </div>
          )}
          <div
            onClick={() => navigate("/Viewscore")}
            className={` ${
              isViewScore
                ? " text-dms-2textblue bg-dms-2navbar "
                : " hover:text-dms-2textblue hover:bg-dms-2navbar "
            } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
          >
            ดูผลคะแนน
          </div>
          <div
            onClick={() => navigate("/faq")}
            className={` ${
              isFaq
                ? " text-dms-2textblue bg-dms-2navbar "
                : " hover:text-dms-2textblue hover:bg-dms-2navbar "
            } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
          >
            คำถาม-คำตอบ
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center  p-2 mx-12 md:mx-8 lg:mx-12 xl:mx-12 cursor-pointer">
        <div
          onClick={() => clickLogOut()}
          className="text-white hover:text-dms-2purpleintensive text-center text-xs md:text-base lg:text-lg xl:text-xl font-Kanit font-normal 
          bg-dms-2purpleintensive hover:bg-dms-2navbar w-44 md:w-24 lg:w-36 xl:w-44 h-16 pt-4 md:-mt-20 
        mx-32 md:-mx-4 lg:-mx-10 xl:mx-0"
        >
          ออกจากระบบ
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// const ActiveLesson = () => {
//   const navigate = useNavigate();
//   return (
//     <div
//       onClick={() => navigate("/lesson")}
//       className={` ${
//         isLesson
//           ? " text-dms-2textblue bg-dms-2navbar "
//           : " hover:text-dms-2textblue hover:bg-dms-2navbar "
//       } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
//     >
//       เข้าสู่บทเรียน
//     </div>
//   );
// };

// const InActiveLesson = () => {
//   const navigate = useNavigate();
//   return (
//     <div
//       className={` ${
//         isLesson
//           ? " text-dms-2textblue bg-dms-2navbar "
//           : " hover:text-dms-2textblue hover:bg-dms-2navbar "
//       } rounded-b-sm  p-2 md:p-4 cursor-pointer`}
//     >
//       เข้าสู่บทเรียน
//     </div>
//   );
// };
