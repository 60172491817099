import { DateTime } from "luxon";

export const BunketUrl =
  "https://bucketdmssss132254-dev.s3.ap-southeast-1.amazonaws.com/public/";

export const FunctionGetPathUrl = (key) => {
  if (key) {
    return `${BunketUrl}${key}`;
  } else {
    return;
  }
};

export const toDateTimeTH = (date) =>
  DateTime.fromISO(date).toFormat("MM-dd-yyyy");

export const toFullDateTH = (date) =>
  DateTime.fromISO(date).setLocale("th").toLocaleString(DateTime.DATE_FULL);

export const toTimestamp = (date) => {
  if (date) {
    return DateTime.fromISO(date).setZone("UTC+7").toISO();
  }
  return DateTime.now().setZone("UTC+7").toISO();
};
