import { useNavigate } from "react-router-dom";

const FunctionLogOut = () => {
  const navigate = useNavigate();
  //   const dispatch = useDispatch();
  const onClickSignOut = () => {
    // localStorage.clear();
    // dispatch(initAuth());
    navigate("/login");
  };
  return (
    <div onClick={onClickSignOut} className="">
      {/* <div className="flex cursor-pointer hover:text-dms-bluetext text-dms-button  hover:bg-dms-button bg-dms-zoom   w-36 p-3 absolute top-36 right-4 text-xl font-DBAdmanX rounded-xl ">
        ออกจากระบบ
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            className=" md:w-6 lg:w-6 xl:w-6 md:h-6 lg:h-6 xl:h-6 md:ml-3 lg:ml-3 xl:ml-3"
          >
            <g fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M11 20a1 1 0 0 0-1-1H5V5h5a1 1 0 1 0 0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h5a1 1 0 0 0 1-1z"
                clip-rule="evenodd"
              />
              <path d="M21.714 12.7a.996.996 0 0 0 .286-.697v-.006a.997.997 0 0 0-.293-.704l-4-4a1 1 0 1 0-1.414 1.414L18.586 11H9a1 1 0 1 0 0 2h9.586l-2.293 2.293a1 1 0 0 0 1.414 1.414l4-4l.007-.007z" />
            </g>
          </svg>{" "}
        </div>
      </div> */}
    </div>
  );
};
export default FunctionLogOut;
