import React, { useState } from "react";
import { BASE_PAGE, CornerLogo } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import api from "../../api";
import { useIsMobile } from "../../utils/ismobile";
import "./text.css";

const FunctionLogin = async (email, setUser, navigate) => {
  try {
    const { data } = await api.post(`/users/login`, {
      email: String(email)?.toLowerCase()?.trim(),
      // password: String(password)?.toLowerCase()?.trim(),
    });

    console.log(data);

    if (data?.email) {
      // sessionStorage.setItem("username", data.username);
      sessionStorage.setItem("email", data.email);
      setUser(data);
      navigate("/menu");
    } else {
      alert("กรอกไอดีหรืออีเมลของท่านไม่ถูกต้อง");
    }
  } catch (error) {
    console.error("Auth error : ", error);
    alert("กรอกไอดีหรืออีเมลของท่านไม่ถูกต้อง : ", error);
    return { user: null };
  }
};

const LoginOnSubmit = async (
  username,
  // password,
  setLoading,
  setError,
  setErrorMessages,
  setUser,
  navigate
) => {
  try {
    setLoading(true);
    FunctionLogin(username, setUser, navigate);
  } catch (error) {
    alert("error  ");
  } finally {
    setLoading(false);
  }
};

const Login = () => {
  // const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  // const [showPassword, setShowPassword] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessages] = React.useState("");
  const [error, setError] = React.useState(false);

  const user = SystemStoreUser((state) => state.user);
  const setUser = SystemStoreUser((state) => state.setUser);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  // const togglePassword = () => {
  //   setShowPassword(!showPassword);
  // };

  return (
    // <div className={BASE_PAGE}>
    //   <div className="font-DBAdmanX font-semibold w-full  md:pt-28  lg:pt-20 xl:pt-20 text-dms-bluetext md:text-4xl lg:text-5xl xl:text-6xl">
    //     ระบบทดสอบการพัฒนาศักยภาพบุคลากร
    //     <div className="w-full md:mt-2 lg:mt-2 xl:mt-2 text-dms-bluetext md:text-4xl lg:text-5xl xl:text-6xl">
    //       เพื่อการจัดตั้งคลินิกผู้สูงอายุ
    //     </div>
    //   </div>
    //   <div className="relative md:w-6/12 lg:w-2/5 xl:w-2/5 mx-auto md:mt-16 lg:mt-16 xl:mt-20 ">
    //     <img
    //       src="images/logincard.png"
    //       className="w-full md:h-72 lg:h-72 xl:h-72  opacity-90 "
    //     />
    //     {/* ปิดเที่ยงคืน */}
    //     <div
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         LoginOnSubmit(
    //           username,
    //           password,
    //           setLoading,
    //           setError,
    //           setErrorMessages,
    //           setUser,
    //           navigate
    //         );
    //       }}
    //       className="absolute font-DBAdmanX font-semibold top-6 left-10 md:text-3xl lg:text-3xl xl:text-4xl text-dms-graytext"
    //     >
    //       เข้าสู่ระบบ
    //     </div>
    //     <input
    //       value={username}
    //       onChange={(e) => setUsername(e.target.value)}
    //       className="focus bg-white md:text-xl lg:text-xl xl:text-2xl p-2 md:h-10 lg:h-12  xl:h-12 absolute md:top-20 lg:top-20 xl:top-20 left-10 rounded-lg md:w-9/12 lg:w-80 xl:w-5/6"
    //     ></input>
    //     <div className="absolute font-DBAdmanX font-semibold md:top-36 lg:top-36 xl:top-36 left-10 md:text-3xl lg:text-3xl xl:text-4xl text-dms-graytext">
    //       รหัสผ่าน
    //     </div>
    //     <input
    //       type={showPassword ? "password" : "text"}
    //       value={password}
    //       onChange={(e) => setPassword(e.target.value)}
    //       className="bg-white md:text-xl lg:text-xl xl:text-2xl p-2  md:h-10 lg:h-12  xl:h-12 absolute md:top-48 lg:top-48 xl:top-48 left-10 rounded-lg md:w-9/12 lg:w-80 xl:w-5/6"
    //     ></input>{" "}
    //     <div className="relative flex justify-end ">
    //       <div className="absolute  md:bottom-16 lg:bottom-14  xl:bottom-14 right-16 cursor-pointer">
    //         {showPassword ? (
    //           <div onClick={togglePassword}>
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width=""
    //               height=""
    //               viewBox="0 0 256 256"
    //               className=" md:w-6 lg:w-7 xl:w-7 md:h-6 lg:h-7 xl:h-7 "
    //             >
    //               <path
    //                 fill="#465D8E"
    //                 d="M53.9 34.6a8 8 0 0 0-11.8 10.8l19.2 21.1C25 88.8 9.4 123.2 8.7 124.8a8.2 8.2 0 0 0 0 6.5c.3.7 8.8 19.5 27.6 38.4c25.1 25 56.8 38.3 91.7 38.3a128.6 128.6 0 0 0 52.1-10.8l22 24.2a8 8 0 0 0 5.9 2.6a8.2 8.2 0 0 0 5.4-2.1a7.9 7.9 0 0 0 .5-11.3Zm47.3 75.9l41.7 45.8A31.6 31.6 0 0 1 128 160a32 32 0 0 1-26.8-49.5ZM128 192c-30.8 0-57.7-11.2-79.9-33.3A128.3 128.3 0 0 1 25 128c4.7-8.8 19.8-33.5 47.3-49.4l18 19.8a48 48 0 0 0 63.6 70l14.7 16.2A112.1 112.1 0 0 1 128 192Zm119.3-60.7c-.4.9-10.5 23.3-33.4 43.8a8.1 8.1 0 0 1-5.3 2a7.6 7.6 0 0 1-5.9-2.7a8 8 0 0 1 .6-11.3A131 131 0 0 0 231 128a130.3 130.3 0 0 0-23.1-30.8C185.7 75.2 158.8 64 128 64a112.9 112.9 0 0 0-19.4 1.6a8.1 8.1 0 0 1-9.2-6.6a8 8 0 0 1 6.6-9.2a132.4 132.4 0 0 1 22-1.8c34.9 0 66.6 13.3 91.7 38.3c18.8 18.9 27.3 37.7 27.6 38.5a8.2 8.2 0 0 1 0 6.5ZM134 96.6a8 8 0 0 1 3-15.8a48.3 48.3 0 0 1 38.8 42.7a8 8 0 0 1-7.2 8.7h-.8a7.9 7.9 0 0 1-7.9-7.2A32.2 32.2 0 0 0 134 96.6Z"
    //               />
    //             </svg>
    //           </div>
    //         ) : (
    //           <svg
    //             onClick={togglePassword}
    //             xmlns="http://www.w3.org/2000/svg"
    //             width=""
    //             height=""
    //             viewBox="0 0 256 256"
    //             className=" md:w-6 lg:w-7 xl:w-7 md:h-6 lg:h-7 xl:h-7"
    //           >
    //             <path
    //               fill="#465d8e"
    //               d="M247.3 124.8c-.3-.8-8.8-19.6-27.6-38.5C194.6 61.3 162.9 48 128 48S61.4 61.3 36.3 86.3C17.5 105.2 9 124 8.7 124.8a7.9 7.9 0 0 0 0 6.4c.3.8 8.8 19.6 27.6 38.5c25.1 25 56.8 38.3 91.7 38.3s66.6-13.3 91.7-38.3c18.8-18.9 27.3-37.7 27.6-38.5a7.9 7.9 0 0 0 0-6.4ZM128 192c-30.8 0-57.7-11.2-79.9-33.3A130.3 130.3 0 0 1 25 128a130.3 130.3 0 0 1 23.1-30.8C70.3 75.2 97.2 64 128 64s57.7 11.2 79.9 33.2A130.3 130.3 0 0 1 231 128c-7.2 13.5-38.6 64-103 64Zm0-112a48 48 0 1 0 48 48a48 48 0 0 0-48-48Zm0 80a32 32 0 1 1 32-32a32.1 32.1 0 0 1-32 32Z"
    //             />
    //           </svg>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <button
    //     onClick={(e) => {
    //       e.stopPropagation();
    //       LoginOnSubmit(
    //         username,
    //         password,
    //         setLoading,
    //         setError,
    //         setErrorMessages,
    //         setUser,
    //         navigate
    //       );
    //     }}
    //     className="text-white md:w-6/12 lg:w-2/5 xl:w-2/5 md:mt-16  lg:mt-10 xl:mt-10 font-bold hover:bg-blue-300 bg-dms-gradientt p-4 rounded-2xl text-4xl"
    //   >
    //     <div
    //       // onClick={() => navigate("/menu")}
    //       className=" font-DBAdmanX font-semibold  md:text-3xl lg:text-3xl xl:text-3xl text-dms-bluetext"
    //     >
    //       เข้าสู่ระบบ
    //     </div>
    //   </button>

    //   <CornerLogo />
    // </div>
    <div
      style={
        isMobile
          ? {
              backgroundImage: "url(/images/bgphone.png)",
              backgroundPosition: "center",

              height: "100vh",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              marginBottom: "-40px",
            }
          : {
              backgroundImage: "url(/images/backgound.png)",
              backgroundPosition: "center",
              height: "100vh",
              width: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              marginTop: "-50px",
            }
      }
      className={BASE_PAGE}
    >
      <div className="font-Kanit font-semibold w-full pt-20 md:pt-28  lg:pt-20 xl:pt-28 text-dms-2textblue text-lg md:text-4xl lg:text-5xl xl:text-5xl">
        การพัฒนาศักยภาพบุคลากร
        <div className="w-full md:mt-2 lg:mt-2 xl:mt-2 text-dms-2textblue text-lg md:text-4xl lg:text-5xl xl:text-5xl">
          สาธารณสุขเพื่อการจัดตั้งคลินิกผู้สูงอายุ
        </div>
      </div>
      <div className=" w-80 md:w-10/12 lg:w-10/12 xl:w-[55%] relative  mx-auto mt-16 md:mt-16 lg:mt-16 xl:mt-6  h-fit mb-auto ">
        <div
          className=" bg-white/90 rounded-[18px] border-2 border-dms-2purplelight w-full 
        md:h-1/2 lg:h-5/6 xl:h-1/2  opacity-90 "
        >
          <div className=" text-dms-2purpleintensive text-lg   md:text-3xl  xl:text-4xl  mt-10 md:mt-16   font-semibold">
            {isMobile ? (
              <div>
                สามารถเข้าเรียน E-Learning
                <div>ได้ที่เว็บไซต์ใหม่</div>
              </div>
            ) : (
              "สามารถเข้าเรียน E-Learning ได้ที่เว็บไซต์ใหม่"
            )}
          </div>
          <div
            onClick={() =>
              window?.open("https://elearning.dms.go.th/elearning/")
            }
            className="  cursor-pointer text-[#086CFC] text-sm md:text-2xl font-semibold mt-1"
          >
            https://elearning.dms.go.th/elearning/
          </div>
          {/* ------ เข้าสู่ระบบ ------ */}

          {/* <div className="  font-semibold  text-xl md:text-2xl lg:text-3xl xl:text-2xl text-dms-2textblue mt-6">
            เข้าสู่ระบบ
          </div>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder={"กรอกอีเมลของท่านให้ถูกต้อง"}
            // classNameplaceholder="text-white"
            className="focus:outline-none rounded-2xl bg-[#C1C1C1] text-neutral-500  font-medium placeholder:text-white/50 text-sm md:text-base lg:text-lg xl:text-sm p-4 md:h-10 lg:h-12  xl:h-12  
              w-10/12 md:w-10/12 lg:w-10/12 xl:w-10/12 mt-6 "
          /> */}
          {/* <div className=" relative mx-auto mt-4">
            <img src="/images/ปุ่มเข้าสู่ระบบ.png" className="w-full "></img>
            <button
              onClick={(e) => {
                e.stopPropagation();
                LoginOnSubmit(
                  username,
                  // password,
                  setLoading,
                  setError,
                  setErrorMessages,
                  setUser,
                  navigate
                );
              }}
              className=" w-full md:w-60 py-2  bg-dms-lightpurple rounded-full mx-auto"
            >
              <div
                // onClick={() => navigate("/menu")}
                className="  font-semibold text-xl md:text-3xl text-dms-2purpleintensive  hover:text-dms-2textblue"
              >
                เข้าสู่ระบบ
              </div>
              <div
                className={`w-full md:w-60 py-2 bg-dms-2textblue rounded-full mx-auto absolute top-[30%] left-[51%] -translate-x-1/2 -z-10`}
              >
                <div
                  className={` font-semibold text-3xl
                              text-[#15496E] hover:text-dms-bluetext`}
                >
                  .
                </div>
              </div>
            </button>
          </div> */}

          {/* ------ ลงทะเบียน ------ */}
          <div className="bg-dms-2purpleintensive  w-64 md:w-96 lg:w-10/12 xl:w-10/12 mx-auto  h-0.5 my-6 "></div>

          <div className=" flex items-end w-full justify-center mt-0 md:mt-10">
            <img
              className=" w-[90px] md:w-[126px] h-10 md:h-14 mt-auto"
              alt=""
              src="/images/font_re3gister.png"
            />

            <span className="  text-dms-2purpleintensive text-lg md:text-2xl    font-semibold">
              เพื่อขอรหัสเข้าเรียนได้ที่
            </span>
          </div>
          <div
            onClick={() =>
              window?.open(
                "https://elearning.dms.go.th/elearning/regis_stu.php"
              )
            }
            className="  break-all px-6 md:px-0 cursor-pointer text-[#086CFC] text-sm  md:text-xl font-semibold mt-1 mb-20 md:mb-28"
          >
            https://elearning.dms.go.th/elearning/regis_stu.php
          </div>

          {/* <div className="text-dms-2textblue text-lg md:text-xl  font-semibold mt-4">
            สำหรับผู้ที่ไม่เคยผ่านการอบรม
          </div>
          <div className="text-dms-2textblue text-lg md:text-xl font-Kanit font-semibold -mt-2">
            กรุณาลงทะเบียนก่อน
          </div>
          <div className="relative mx-auto mt-4 mb-16">
            <img src="/images/ปุ่มเข้าสู่ระบบ.png" className="w-full "></img>
            <button className="w-full md:w-60 py-2  bg-dms-2purpleintensive rounded-full mx-auto">
              <div
                onClick={() => navigate("/register")}
                className="font-semibold text-xl md:text-3xl text-white  hover:text-dms-2textblue"
              >
                ลงทะเบียน
              </div>
              <div
                className={`w-full md:w-60 py-2 bg-dms-2textblue rounded-full mx-auto absolute top-[30%] left-[51%] -translate-x-1/2 -z-10`}
              >
                <div
                  className={` font-semibold text-3xl
                              text-[#15496E] hover:text-dms-bluetext`}
                >
                  .
                </div>
              </div>
            </button>
          </div> */}

          {/* <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="focus bg-white md:text-xl lg:text-xl xl:text-2xl p-2 md:h-10 lg:h-12  xl:h-12 absolute md:top-20 lg:top-20 xl:top-20 left-10 rounded-lg md:w-9/12 lg:w-80 xl:w-5/6"
        ></input>
        <div className="absolute font-DBAdmanX font-semibold md:top-36 lg:top-36 xl:top-36 left-10 md:text-3xl lg:text-3xl xl:text-4xl text-dms-graytext">
          รหัสผ่าน
        </div>
        <input
          type={showPassword ? "password" : "text"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="bg-white md:text-xl lg:text-xl xl:text-2xl p-2  md:h-10 lg:h-12  xl:h-12 absolute md:top-48 lg:top-48 xl:top-48 left-10 rounded-lg md:w-9/12 lg:w-80 xl:w-5/6"
        ></input>{" "}
        <div className="relative flex justify-end ">
          <div className="absolute  md:bottom-16 lg:bottom-14  xl:bottom-14 right-16 cursor-pointer">
            {showPassword ? (
              <div onClick={togglePassword}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width=""
                  height=""
                  viewBox="0 0 256 256"
                  className=" md:w-6 lg:w-7 xl:w-7 md:h-6 lg:h-7 xl:h-7 "
                >
                  <path
                    fill="#465D8E"
                    d="M53.9 34.6a8 8 0 0 0-11.8 10.8l19.2 21.1C25 88.8 9.4 123.2 8.7 124.8a8.2 8.2 0 0 0 0 6.5c.3.7 8.8 19.5 27.6 38.4c25.1 25 56.8 38.3 91.7 38.3a128.6 128.6 0 0 0 52.1-10.8l22 24.2a8 8 0 0 0 5.9 2.6a8.2 8.2 0 0 0 5.4-2.1a7.9 7.9 0 0 0 .5-11.3Zm47.3 75.9l41.7 45.8A31.6 31.6 0 0 1 128 160a32 32 0 0 1-26.8-49.5ZM128 192c-30.8 0-57.7-11.2-79.9-33.3A128.3 128.3 0 0 1 25 128c4.7-8.8 19.8-33.5 47.3-49.4l18 19.8a48 48 0 0 0 63.6 70l14.7 16.2A112.1 112.1 0 0 1 128 192Zm119.3-60.7c-.4.9-10.5 23.3-33.4 43.8a8.1 8.1 0 0 1-5.3 2a7.6 7.6 0 0 1-5.9-2.7a8 8 0 0 1 .6-11.3A131 131 0 0 0 231 128a130.3 130.3 0 0 0-23.1-30.8C185.7 75.2 158.8 64 128 64a112.9 112.9 0 0 0-19.4 1.6a8.1 8.1 0 0 1-9.2-6.6a8 8 0 0 1 6.6-9.2a132.4 132.4 0 0 1 22-1.8c34.9 0 66.6 13.3 91.7 38.3c18.8 18.9 27.3 37.7 27.6 38.5a8.2 8.2 0 0 1 0 6.5ZM134 96.6a8 8 0 0 1 3-15.8a48.3 48.3 0 0 1 38.8 42.7a8 8 0 0 1-7.2 8.7h-.8a7.9 7.9 0 0 1-7.9-7.2A32.2 32.2 0 0 0 134 96.6Z"
                  />
                </svg>
              </div>
            ) : (
              <svg
                onClick={togglePassword}
                xmlns="http://www.w3.org/2000/svg"
                width=""
                height=""
                viewBox="0 0 256 256"
                className=" md:w-6 lg:w-7 xl:w-7 md:h-6 lg:h-7 xl:h-7"
              >
                <path
                  fill="#465d8e"
                  d="M247.3 124.8c-.3-.8-8.8-19.6-27.6-38.5C194.6 61.3 162.9 48 128 48S61.4 61.3 36.3 86.3C17.5 105.2 9 124 8.7 124.8a7.9 7.9 0 0 0 0 6.4c.3.8 8.8 19.6 27.6 38.5c25.1 25 56.8 38.3 91.7 38.3s66.6-13.3 91.7-38.3c18.8-18.9 27.3-37.7 27.6-38.5a7.9 7.9 0 0 0 0-6.4ZM128 192c-30.8 0-57.7-11.2-79.9-33.3A130.3 130.3 0 0 1 25 128a130.3 130.3 0 0 1 23.1-30.8C70.3 75.2 97.2 64 128 64s57.7 11.2 79.9 33.2A130.3 130.3 0 0 1 231 128c-7.2 13.5-38.6 64-103 64Zm0-112a48 48 0 1 0 48 48a48 48 0 0 0-48-48Zm0 80a32 32 0 1 1 32-32a32.1 32.1 0 0 1-32 32Z"
                />
              </svg>
            )}
          </div>
        </div> */}
        </div>
        <div className=" absolute bottom-0  mx-auto w-full bg-[#AB61A1]  rounded-[18px]  h-14 mb-auto flex flex-col justify-center text-white">
          <div className=" text-xs md:text-sm font-light">
            สามารถติดต่อสอบถามเพิ่มเติมได้ที่
          </div>
          <div className=" text-xs md:text-base font-medium">
            เบอร์โทรศัพท์{" "}
            <span>
              <a href="tel:025906211">02-024-8481</a>
            </span>{" "}
            ต่อ 204 หรือ{" "}
            <span>
              <a href="tel:0933215288">093-321-5288</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
