import React from "react";

const FieldErrorRequired = ({ error }) => {
  if (error) {
    return (
      <div className="w-72 mx-auto mt-2 text-red-600 text-xs p-2 bg-red-50 border-b-2 border-red-600 flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className=" w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokewidth="1"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        {error}
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};

export default FieldErrorRequired;
