export const mockHospital = [
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลเชียงรายประชานุเคราะห์",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลพาน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลแม่จัน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลแม่สาย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเทิง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเวียงป่าเป้า",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชเชียงของ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลขุนตาล",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเชียงแสน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลป่าแดด",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพญาเม็งราย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ฟ้าหลวง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ลาว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่สรวย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเวียงแก่น",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเวียงเชียงรุ้ง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสมเด็จพระญาณสังวร",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงราย",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลดอยหลวง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลนครพิงค์",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลจอมทอง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลฝาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลสันทราย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสันป่าตอง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลหางดง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเชียงดาว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไชยปราการ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอยเต่า",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอยสะเก็ด",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอยหล่อ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเทพรัตนเวชชานุกูลเฉลิมพระเกียรติ ๖๐ พรรษา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพร้าว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่แตง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่วาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ออน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่อาย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเวียงแหง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสะเมิง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสันกำแพง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสารภี",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลอมก๋อย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลฮอด",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลแม่ตื่น",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "เชียงใหม่",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลวัดจันทร์เฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลน่าน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชปัว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเวียงสา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเฉลิมพระเกียรติ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเชียงกลาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าวังผา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งช้าง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาน้อย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาหมื่น",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ่อเกลือ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านหลวง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่จริม",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสองแคว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสันติสุข",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "น่าน",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลภูเพียง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพะเยา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลเชียงคำ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจุน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเชียงม่วน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอกคำใต้",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ใจ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลภูกามยาว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "พะเยา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลภูซาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลแพร่",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชเด่นชัย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลร้องกวาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลอง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังชิ้น",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสอง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสูงเม่น",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แพร่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองม่วงไข่",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลศรีสังวาลย์",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลแม่สะเรียง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลปาย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลขุนยวม",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปางมะผ้า",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ลาน้อย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "แม่ฮ่องสอน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสบเมย",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลลำปาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลเกาะคา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลเถิน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลงาว",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแจ้ห่ม",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเมืองปาน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ทะ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่พริก",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่เมาะ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังเหนือ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสบปราบ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเสริมงาม",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำปาง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้างฉัตร",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลลำพูน",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลป่าซาง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลลี้",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งหัวช้าง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านธิ",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านโฮ่ง",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ทา",
  },
  {
    เขตสุขภาพ: 1,
    จังหวัด: "ลำพูน",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเวียงหนองล่อง",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลแม่สอด",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสมเด็จพระเจ้าตากสินมหาราช",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลท่าสองยาง",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลแม่ระมาด",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลอุ้มผาง",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลพบพระ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านตาก",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสามเงา",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "ตาก",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลวังเจ้า",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลพุทธชินราช",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชนครไทย",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลวังทอง",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชาติตระการ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเนินมะปราง",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางกระทุ่ม",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางระกำ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพรหมพิราม",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "พิษณุโลก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวัดโบสถ์",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลเพชรบูรณ์",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลวิเชียรบุรี",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลหล่มสัก",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชหล่มเก่า",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลหนองไผ่",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาค้อ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชนแดน",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบึงสามพัน",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังโป่ง",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีเทพ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "เพชรบูรณ์",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลน้ำหนาว",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสุโขทัย",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลศรีสังวรสุโขทัย",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสวรรคโลก",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลศรีสัชนาลัย",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกงไกรลาศ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคีรีมาศ",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งเสลี่ยม",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านด่านลานหอย",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "สุโขทัย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีนคร",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลอุตรดิตถ์",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลน้ำปาด",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลตรอน",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทองแสนขัน",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าปลา",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านโคก",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพิชัย",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลฟากท่า",
  },
  {
    เขตสุขภาพ: 2,
    จังหวัด: "อุตรดิตถ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลับแล",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลกำแพงเพชร",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลขาณุวรลักษบุรี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลคลองขลุง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคลองลาน",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทรายทองวัฒนา",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไทรงาม",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบึงสามัคคี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปางศิลาทอง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพรานกระต่าย",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลานกระบือ",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลโกสัมพีนคร",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "กำแพงเพชร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลทุ่งโพธิ์ทะเล",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลชัยนาทนเรนทร",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสรรคบุรี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลมโนรมย์",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวัดสิงห์",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสรรพยา",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหันคา",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเนินขาม",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "ชัยนาท",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลหนองมะโมง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสวรรค์ประชารักษ์",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลตาคลี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลลาดยาว",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลชุมแสง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลท่าตะโก",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบรรพตพิสัย",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเก้าเลี้ยว",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโกรกพระ",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลตากฟ้า",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพยุหะคีรี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไพศาลี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่วงก์",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองบัว",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "นครสวรรค์",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลชุมตาบง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพิจิตร",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบางมูลนาก",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชตะพานหิน",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทับคล้อ",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพทะเล",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพธิ์ประทับช้าง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวชิรบารมี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังทรายพูน",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสามง่าม",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลดงเจริญ",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบึงนาราง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "พิจิตร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลสากเหล็ก",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลอุทัยธานี",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลหนองฉาง",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทัพทัน",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านไร่",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลานสัก",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสว่างอารมณ์",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยคต",
  },
  {
    เขตสุขภาพ: 3,
    จังหวัด: "อุทัยธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลหนองขาหย่าง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นครนายก",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลนครนายก",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นครนายก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านนา",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นครนายก",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลองครักษ์",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นครนายก",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลปากพลี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลพระนั่งเกล้า",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบางบัวทอง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบางใหญ่",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลไทรน้อย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบางกรวย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลปากเกร็ด",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางบัวทอง ๒",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "นนทบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลศูนย์บริการการแพทย์นนทบุรี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลปทุมธานี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลธัญบุรี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลคลองหลวง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลประชาธิปัตย์",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลาดหลุมแก้ว",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลำลูกกา",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสามโคก",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ปทุมธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองเสือ",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลพระนครศรีอยุธยา",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลเสนา",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบางปะอิน",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลวังน้อย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าเรือ",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางไทร",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางบาล",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางปะหัน",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลผักไห่",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภาชี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลาดบัวหลวง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสมเด็จพระสังฆราช(นครหลวง)",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลอุทัย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบางซ้าย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบ้านแพรก",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "พระนครศรีอยุธยา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลมหาราช",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพระนารายณ์มหาราช",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลบ้านหมี่",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลโคกสำโรง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลชัยบาดาล",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลพัฒนานิคม",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโคกเจริญ",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าวุ้ง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าหลวง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลำสนธิ",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองม่วง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "ลพบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลสระโบสถ์",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสระบุรี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลพระพุทธบาท",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลแก่งคอย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านหมอ",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลมวกเหล็ก",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังม่วงสัทธรรม",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวิหารแดง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเสาไห้เฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองแค",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองแซง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลดอนพุด",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สระบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลหนองโดน",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สิงห์บุรี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสิงห์บุรี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สิงห์บุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลอินทร์บุรี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สิงห์บุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลค่ายบางระจัน",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สิงห์บุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าช้าง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สิงห์บุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางระจัน",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "สิงห์บุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลพรหมบุรี",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลอ่างทอง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลวิเศษชัยชาญ",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไชโย",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลป่าโมก",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพธิ์ทอง",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแสวงหา",
  },
  {
    เขตสุขภาพ: 4,
    จังหวัด: "อ่างทอง",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลสามโก้",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพหลพลพยุหเสนา",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลมะการักษ์",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลทองผาภูมิ",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระสังฆราชองค์ที่๑๙",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบ่อพลอย",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเจ้าคุณไพบูลย์พนมทวน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลด่านมะขามเตี้ย",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่ากระดาน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไทรโยค",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเลาขวัญ",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสถานพระบารมี",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสมเด็จพระปิยมหาราชรมณียเขต",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสังขละบุรี",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยกระเจาเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลศุกร์ศิริศรีสวัสดิ์",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "กาญจนบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลหนองปรือ",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลนครปฐม",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลกำแพงแสน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสามพราน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบางเลน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอนตูม",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนครชัยศรี",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพุทธมณฑล",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหลวงพ่อเปิ่น",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "นครปฐม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยพลู",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลประจวบคีรีขันธ์",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลหัวหิน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลบางสะพาน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสามร้อยยอด",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุยบุรี",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทับสะแก",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางสะพานน้อย",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ประจวบคีรีขันธ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปราณบุรี",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพระจอมเกล้า",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลชะอำ",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลท่ายาง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแก่งกระจาน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาย้อย",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านลาด",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านแหลม",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "เพชรบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองหญ้าปล้อง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลราชบุรี",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลบ้านโป่ง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลดำเนินสะดวก",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลโพธาราม",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชจอมบึง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเจ็ดเสมียน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางแพ",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปากท่อ",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวัดเพลง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสวนผึ้ง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "ราชบุรี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบ้านคา",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สมุทรสงคราม",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสมเด็จพระพุทธเลิศหล้า",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สมุทรสงคราม",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลนภาลัย",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สมุทรสงคราม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลอัมพวา",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สมุทรสาคร",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสมุทรสาคร",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สมุทรสาคร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลกระทุ่มแบน",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลเจ้าพระยายมราช",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลสมเด็จพระสังฆราชองค์ที่๑๗",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลอู่ทอง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลด่านช้าง",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเดิมบางนางบวช",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอนเจดีย์",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางปลาม้า",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีประจันต์",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสามชุก",
  },
  {
    เขตสุขภาพ: 5,
    จังหวัด: "สุพรรณบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองหญ้าไซ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลพระปกเกล้า",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลขลุง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลนายายอาม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลมะขาม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสอยดาว",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแก่งหางแมว",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาคิชฌกูฏ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาสุกิม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าใหม่",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโป่งน้ำร้อน",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสองพี่น้อง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "จันทบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแหลมสิงห์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลพุทธโสธร",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลพนมสารคาม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสนามชัยเขต",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบางน้ำเปรี้ยว",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบางปะกง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าตะเกียบ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางคล้า",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านโพธิ์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแปลงยาว",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลราชสาส์น",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ฉะเชิงเทรา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลคลองเขื่อน",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลชลบุรี",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลบางละมุง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลพนัสนิคม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบ้านบึง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลแหลมฉบัง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลพานทอง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสัตหีบกม.๑๐",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกาะจันทร์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกาะสีชัง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ่อทอง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวัดญาณสังวราราม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ชลบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองใหญ่",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลตราด",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกาะช้าง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาสมิง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคลองใหญ่",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ่อไร่",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแหลมงอบ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ตราด",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเกาะกูด",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลเจ้าพระยาอภัยภูเบศร",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลกบินทร์บุรี",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาดี",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านสร้าง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลประจันตคาม",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีมหาโพธิ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ปราจีนบุรี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีมโหสถ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลระยอง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลแกลง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "M1",
    Hospital:
      "โรงพยาบาลเฉลิมพระเกียรติสมเด็จพระเทพรัตนราชสุดาฯ สยามบรมราชกุมารี ระยอง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบ้านฉาง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลปลวกแดง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาชะเมาเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนิคมพัฒนา",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านค่าย",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "ระยอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังจันทร์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สมุทรปราการ",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสมุทรปราการ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สมุทรปราการ",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลบางพลี",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สมุทรปราการ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบางบ่อ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สมุทรปราการ",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบางจาก",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สมุทรปราการ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพระสมุทรเจดีย์สวาทยานนท์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สมุทรปราการ",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบางเสาธง",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชสระแก้ว",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลอรัญประเทศ",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลวังน้ำเย็น",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาฉกรรจ์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคลองหาด",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลตาพระยา",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังสมบูรณ์",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวัฒนานคร",
  },
  {
    เขตสุขภาพ: 6,
    จังหวัด: "สระแก้ว",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลโคกสูง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "S",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลกาฬสินธุ์",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลกมลาไสย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลยางตลาด",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชกุฉินารายณ์",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลหนองกุงศรี",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาวง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคำม่วง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าคันโท",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาคู",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนามน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลร่องคำ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสหัสขันธ์",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสามชัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยผึ้ง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยเม็ก",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลฆ้องชัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "กาฬสินธุ์",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลดอนจาน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลขอนแก่น",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลชุมแพ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลสิรินธร จังหวัดขอนแก่น",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลน้ำพอง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบ้านไผ่",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลพล",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชกระนวน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลภูเวียง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลมัญจาคีรี",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลหนองเรือ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลหนองสองห้อง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาสวนกวาง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชนบท",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลซำสูง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านฝาง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเปือยน้อย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพระยืน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภูผาม่าน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแวงน้อย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแวงใหญ่",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสีชมพู",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลอุบลรัตน์",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลโคกโพธิ์ไชย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลโนนศิลา",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเวียงเก่า",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ขอนแก่น",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลหนองนาคำ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลมหาสารคาม",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลโกสุมพิสัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบรบือ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลพยัคฆภูมิพิสัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลวาปีปทุม",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกันทรวิชัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุดรัง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแกดำ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเชียงยืน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาเชือก",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาดูน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลยางสีสุราช",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "มหาสารคาม",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลชื่นชม",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลร้อยเอ็ด",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลเกษตรวิสัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลโพนทอง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสุวรรณภูมิ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลเสลภูมิ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลพนมไพร",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจตุรพักตรพิมาน",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจังหาร",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลธวัชบุรี",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปทุมรัตต์",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพธิ์ชัย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพนทราย",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเมยวดี",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเมืองสรวง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีสมเด็จ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองพอก",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลอาจสามารถ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเชียงขวัญ",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลทุ่งเขาหลวง",
  },
  {
    เขตสุขภาพ: 7,
    จังหวัด: "ร้อยเอ็ด",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลหนองฮี",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลนครพนม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชธาตุพนม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลศรีสงคราม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าอุเทน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาแก",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาทม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาหว้า",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านแพง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปลาปาก",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพนสวรรค์",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเรณูนคร",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "นครพนม",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลวังยาง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลบึงกาฬ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลเซกา",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโซ่พิสัย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบึงโขงหลง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปากคาด",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพรเจริญ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีวิไล",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "บึงกาฬ",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบุ่งคล้า",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลเลย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลวังสะพุง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชด่านซ้าย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเชียงคาน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าลี่",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาด้วง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปากชม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลผาขาว",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภูกระดึง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภูเรือ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภูหลวง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองหิน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเอราวัณ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "เลย",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลนาแห้ว",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสกลนคร",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชสว่างแดนดิน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลวานรนิวาส",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลพังโคน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบ้านม่วง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลอากาศอำนวย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุดบาก",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุสุมาลย์",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคำตากล้า",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโคกศรีสุพรรณ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเจริญศิลป์",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเต่างอย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพระอาจารย์แบน  ธนากโร",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพระอาจารย์ฝั้นอาจาโร",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพนนาแก้ว",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวาริชภูมิ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลส่องดาว",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "สกลนคร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลนิคมน้ำอูน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลหนองคาย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชท่าบ่อ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลโพนพิสัย",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเฝ้าไร่",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลรัตนวาปี",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีเชียงใหม่",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสังคม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลโพธิ์ตาก",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองคาย",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลสระใคร",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองบัวลำภู",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลหนองบัวลำภู",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองบัวลำภู",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลนากลาง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองบัวลำภู",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลศรีบุญเรือง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองบัวลำภู",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาวังเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองบัวลำภู",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนสัง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "หนองบัวลำภู",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสุวรรณคูหา",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลอุดรธานี",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลกุมภวาปี",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบ้านผือ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลเพ็ญ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชบ้านดุง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลหนองหาน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุดจับ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไชยวาน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งฝน",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนายูง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลน้ำโสม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนสะอาด",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพิบูลย์รักษ์",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังสามหมอ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีธาตุ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสร้างคอม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองวัวซอ",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองแสง",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลกู่แก้ว",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลประจักษ์ศิลปาคม",
  },
  {
    เขตสุขภาพ: 8,
    จังหวัด: "อุดรธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลห้วยเกิ้ง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลชัยภูมิ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลภูเขียวเฉลิมพระเกียรติ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลแก้งคร้อ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลจัตุรัส",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลหนองบัวแดง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบำเหน็จณรงค์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกษตรสมบูรณ์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคอนสวรรค์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคอนสาร",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเทพสถิต",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเนินสง่า",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านเขว้า",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านแท่น",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภักดีชุมพล",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองบัวระเหว",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "ชัยภูมิ",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลซับใหญ่",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลมหาราชนครราชสีมา",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลปากช่องนานา",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลเทพรัตน์นครราชสีมา",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลพิมาย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลครบุรี",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลโชคชัย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลด่านขุนทด",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบัวใหญ่",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสีคิ้ว",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลจักราช",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลชุมพวง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลโนนไทย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลโนนสูง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลประทาย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลปักธงชัย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสูงเนิน",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแก้งสนามนาง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลขามทะเลสอ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลขามสะแกแสง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเฉลิมพระเกียรติ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเฉลิมพระเกียรติสมเด็จย่า ๑๐๐ ปี",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนแดง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านเหลื่อม",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพระทองคำเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลำทะเมนชัย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังน้ำเขียว",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเสิงสาง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองบุญมาก",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยแถลง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเทพารักษ์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบัวลาย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "นครราชสีมา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลสีดา",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลบุรีรัมย์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลนางรอง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลประโคนชัย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลลำปลายมาศ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสตึก",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลกระสัง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลคูเมือง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบ้านกรวด",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลพุทไธสง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลละหานทราย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเฉลิมพระเกียรติ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชำนิ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาโพธิ์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนดินแดง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนสุวรรณ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านด่าน",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านใหม่ไชยพจน์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปะคำ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพลับพลาชัย",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองกี่",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองหงส์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยราช",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "บุรีรัมย์",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลแคนดง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสุรินทร์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลปราสาท",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลศีขรภูมิ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลท่าตูม",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลรัตนบุรี",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสังขะ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลกาบเชิง",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลลำดวน",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจอมพระ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชุมพลบุรี",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนนารายณ์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบัวเชด",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพนมดงรักเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีณรงค์",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสนม",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสำโรงทาบ",
  },
  {
    เขตสุขภาพ: 9,
    จังหวัด: "สุรินทร์",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเขวาสินรินทร์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลมุกดาหาร",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคำชะอี",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดงหลวง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอนตาล",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนิคมคำสร้อย",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองสูง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "มุกดาหาร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหว้านใหญ่",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลยโสธร",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชเลิงนกทา",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุดชุม",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลค้อวัง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคำเขื่อนแก้ว",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทรายมูล",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไทยเจริญ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลป่าติ้ว",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ยโสธร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลมหาชนะชัย",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลศรีสะเกษ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลกันทรลักษ์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลขุขันธ์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลราษีไศล",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลอุทุมพรพิสัย",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลกันทรารมย์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลขุนหาญ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลน้ำเกลี้ยง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโนนคูณ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบึงบูรพ์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเบญจลักษ์เฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปรางค์กู่",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพยุห์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพธิ์ศรีสุวรรณ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไพรบึง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลภูสิงห์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเมืองจันทร์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลยางชุมน้อย",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังหิน",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีรัตนะ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลห้วยทับทัน",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "ศรีสะเกษ",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลศิลาลาด",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลอำนาจเจริญ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชานุมาน",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปทุมราชวงศา",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพนา",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลืออำนาจ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเสนางคนิคม",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อำนาจเจริญ",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหัวตะพาน",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสรรพสิทธิประสงค์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาล๕๐ พรรษา มหาวชิราลงกรณ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชเดชอุดม",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลวารินชำราบ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลตระการพืชผล",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลพิบูลมังสาหาร",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเขื่องใน",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลบุณฑริก",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลม่วงสามสิบ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกุดข้าวปุ้น",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขมราฐ",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโขงเจียม",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอนมดแดง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลตาลสุม",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งศรีอุดม",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาจะหลวย",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาตาล",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาเยีย",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลน้ำยืน",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลโพธิ์ไทร",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีเมืองใหม่",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสว่างวีระวงศ์",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสำโรง",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสิรินธร",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลน้ำขุ่น",
  },
  {
    เขตสุขภาพ: 10,
    จังหวัด: "อุบลราชธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเหล่าเสือโก้ก",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลกระบี่",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกาะลันตา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาพนม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคลองท่อม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปลายพระยา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลำทับ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเหนือคลอง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลอ่าวลึก",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "กระบี่",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเกาะพีพี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลชุมพรเขตรอุดมศักดิ์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลหลังสวน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลท่าแซะ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสวี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งตะโก",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปะทิว",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพะโต๊ะ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลมาบอำมฤต",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลละแม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลปากน้ำชุมพร",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ชุมพร",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลปากน้ำหลังสวน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลมหาราชนครศรีธรรมราช",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลทุ่งสง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสิชล",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลท่าศาลา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลปากพนัง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชฉวาง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลชะอวด",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเชียรใหญ่",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลทุ่งใหญ่",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลร่อนพิบูลย์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลขนอม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจุฬาภรณ์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเฉลิมพระเกียรติ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาบอน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางขัน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพรหมคีรี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพระพรหม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพ่อท่านคล้ายวาจาสิทธิ์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพิปูน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลลานสกา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหัวไทร",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลถ้ำพรรณรา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "นครศรีธรรมราช",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลนบพิตำ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพังงา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลตะกั่วป่า",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกะปงชัยพัฒน์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกาะยาวชัยพัฒน์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคุระบุรีชัยพัฒน์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลตะกั่วทุ่ง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทับปุด",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท้ายเหมืองชัยพัฒน์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "พังงา",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลบางไทร",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ภูเก็ต",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลวชิระภูเก็ต",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ภูเก็ต",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลป่าตอง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ภูเก็ต",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลถลาง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ภูเก็ต",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลฉลอง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ระนอง",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลระนอง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ระนอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกระบุรี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ระนอง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกะเปอร์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ระนอง",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลละอุ่น",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "ระนอง",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลสุขสำราญ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลสุราษฎร์ธานี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลเกาะสมุย",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลกาญจนดิษฐ์",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลไชยา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลท่าโรงช้าง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลบ้านนาสาร",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชเวียงสระ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลพระแสง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเกาะพงัน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคีรีรัฐนิคม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเคียนซา",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลชัยบุรี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลดอนสัก",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าฉาง",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าชนะ",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านตาขุน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบ้านนาเดิม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพนม",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลพุนพิน",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวิภาวดี",
  },
  {
    เขตสุขภาพ: 11,
    จังหวัด: "สุราษฎร์ธานี",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลเกาะเต่า",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลตรัง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลห้วยยอด",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลกันตัง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลย่านตาขาว",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาโยง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปะเหลียน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลรัษฎา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลวังวิเศษ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสิเกา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ตรัง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหาดสำราญเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลนราธิวาสราชนครินทร์",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลสุไหงโก-ลก",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลตากใบ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลระแงะ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลรือเสาะ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจะแนะ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเจาะไอร้อง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบาเจาะ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลยี่งอเฉลิมพระเกียรติ ๘๐ พรรษา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแว้ง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีสาคร",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสุคิริน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "นราธิวาส",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสุไหงปาดี",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลปัตตานี",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชสายบุรี",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลโคกโพธิ์",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกะพ้อ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งยางแดง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปะนาเระ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลมายอ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลแม่ลาน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลไม้แก่น",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลยะรัง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลยะหริ่ง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ปัตตานี",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลหนองจิก",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลพัทลุง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลควนขนุน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลตะโหมด",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกงหรา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลเขาชัยสน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางแก้ว",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปากพะยูน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลป่าบอน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลป่าพะยอม",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลศรีบรรพต",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "พัทลุง",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลศรีนครินทร์(ปัญญานันทภิกขุ)",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลยะลา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "M1",
    Hospital: "โรงพยาบาลเบตง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลรามัน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสมเด็จพระยุพราชยะหา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกรงปินัง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกาบัง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลธารโต",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "ยะลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบันนังสตา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "A",
    Hospital: "โรงพยาบาลหาดใหญ่",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสงขลา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "M2",
    Hospital: "โรงพยาบาลสมเด็จพระบรมราชินีนาถ ณ อำเภอนาทวี",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลเทพา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลระโนด",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลสะเดา",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลกระแสสินธุ์",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลคลองหอยโข่ง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลควนเนียง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลจะนะ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลนาหม่อม",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลบางกล่ำ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลปาดังเบซาร์",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลรัตภูมิ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสทิงพระ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสะบ้าย้อย",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สงขลา",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลสิงหนคร",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "S",
    Hospital: "โรงพยาบาลสตูล",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "F1",
    Hospital: "โรงพยาบาลละงู",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลควนกาหลง",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลควนโดน",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลท่าแพ",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "F2",
    Hospital: "โรงพยาบาลทุ่งหว้า",
  },
  {
    เขตสุขภาพ: 12,
    จังหวัด: "สตูล",
    "ระดับรพ.": "F3",
    Hospital: "โรงพยาบาลมะนัง",
  },
  {
    เขตสุขภาพ: "",
    จังหวัด: "อื่นๆ",
    "ระดับรพ.": "",
    Hospital: "อื่นๆ",
  },
];
