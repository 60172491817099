import React from "react";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import thisImage from "./วุฒิบัตรfinal.png";
import thisImage2 from "./วุฒิบัตรรุ่น2.png";
import thisImage3 from "./วุฒิบัตรไม่ใส่วันที่,ชื่อ.png";

import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import { toFullDateTH } from "../../utils/useFunction";

Font.register({
  family: "Kanit",
  fonts: [
    { src: "/fonts/Kanit/Kanit-Bold.ttf", fontWeight: "Bold" },
    { src: "/fonts/Kanit/Kanit-Regular.ttf", fontWeight: "normal" },
    { src: "/fonts/Kanit/Kanit-Light.ttf", fontWeight: 300 },
    { src: "/fonts/Kanit/Kanit-Medium.ttf", fontWeight: 500 },
    { src: "/fonts//Kanit-ExtraLight.ttf", fontWeight: "ExtraLight" },
    { src: "/fonts/Kanit/Kanit-SemiBold.ttf", fontWeight: 700 },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#ffff",
    position: "relative",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Create Document Component
const Certificate = ({ data, user }) => {
  // const user = SystemStoreUser((state) => state?.user);
  // console.log("user:", user?.generation?.search("รุ่นที่ 2") !== -1);

  const isTwo =
    user?.generation?.search("รุ่นที่ 2") !== -1 ||
    user?.generation === "null" ||
    !user?.generation;

  const isThree = user?.generation3 === true;

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <View>
          <Image
            // src="../pdf/Certificate.png"
            src={isThree ? thisImage3 : isTwo ? thisImage2 : thisImage}
            style={{
              objectFit: "cover",
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              width: "100vw",
              height: "100%",
              flexGrow: 1,
            }}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          {/* <Text
        style={{
          fontFamily: "Kanit",
          top: "25%",
          textAlign: "center",
          fontSize: 18,
          color: "#000000",
          width: "65%",
          fontWeight: 500,
        }}
      >
        สถาบันเวชศาสตร์สมเด็จพระสังฆราชญาณสังวรเพื่อผู้สูงอายุ
      </Text>
      <Text
        style={{
          fontFamily: "Kanit",
          top: "25%",
          textAlign: "center",
          fontSize: 18,
          color: "#000000",
          width: "65%",
          fontWeight: 500,
        }}
      >
        กรมการแพทย์ กระทรวงสาธารณสุข{" "}
      </Text>
      <Text
        style={{
          fontFamily: "Kanit",
          top: "25%",
          textAlign: "center",
          fontSize: 16,
          color: "#000000",
          width: "100%",
          fontWeight: 300,
          marginTop: 3,
        }}
      >
        ขอมอบวุฒิบัตรฉบับนี้เพื่อแสดงว่า{" "}
      </Text> */}

          <Text
            style={{
              fontFamily: "Kanit",
              top: " 40%",
              textAlign: "center",
              fontSize: 40,
              color: "#2A6941",
              width: "100%",
              fontWeight: 800,
            }}
          >
            {user?.prefix} {user?.name_surname}&nbsp;&nbsp;&nbsp;&nbsp;
          </Text>
          {isThree ? (
            <Text
              style={{
                fontFamily: "Kanit",
                top: "53%",
                textAlign: "center",
                fontSize: 16,
                color: "#000000",
                width: "100%",
                fontWeight: 300,
                marginTop: 3,
              }}
            >
              {" "}
              มอบให้ ณ วันที่ {toFullDateTH(user?.updatedAt)}
            </Text>
          ) : (
            ""
          )}
        </View>
      </Page>
    </Document>
  );
};

export default Certificate;
