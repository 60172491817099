import "./App.css";
import Login from "./pages/login";
import Index from "./pages/test";
import { Routes, Route, useLocation } from "react-router-dom";
import Test from "./pages/test";
import { Landing } from "./pages/landing";
import Menu from "./pages/Menu";
import SystemsProtectedRoute from "./systems/Systems.ProtectRoute";
import { SystemStoreUser } from "./systems/Systems.StorageUser";
import Register from "./pages/register";
import Learn from "./pages/learn";
import Score from "./pages/score";
import { CornerLogo } from "./utils/constant";
import Navbar from "./utils/navBar";
import { useIsMobile } from "./utils/ismobile";
import NavbarMobile from "./utils/navBarMobile";
import Lesson from "./pages/lesson";
import Viewscore from "./pages/viewscore";
import Faq from "./pages/Faq";

function App() {
  const isMobile = useIsMobile();

  const Footer = () => {
    const location = useLocation();
    // <div className="w-full h-11 bg-blue-700 absolute bottom-0 flex ">
    //   <img src="images/logodms.png" className="w-11 h-11 mx-8 "></img>
    //   <div className=" text-white text-xl mt-2">สถาบันเวชศาสตร์สมเด็จพระสังฆราชญาณสังวรเพื่อผู้สูงอายุ กรมการแพทย์ กระทรวงสาธารณสุข</div>
    // </div>
    return (
      <div className="">
        <div className=" flex justify-center items-center bg-dms-2purpleintensive w-full h-auto p-1 md:p-2  ">
          <div className=" flex justify-center md:items-center gap-1 ">
            <img src="/images/llogodms.png" className=" h-4 md:h-8 "></img>
            <div className="text-white text-xs md:text-sm font-Kanit font-light text-center">
              สถาบันเวชศาสตร์สมเด็จพระสังฆราชญาณสังวรเพื่อผู้สูงอายุ กรมการแพทย์
              กระทรวงสาธารณสุข
            </div>
          </div>
          <div className="flex justify-center items-center gap-1 pt-1">
            <img src="/images/phone.png" className="w-3 "></img>
            <div className="text-white text-xs md:text-sm font-Kanit font-light ">
              02-5906211 ต่อ 814 , 818
            </div>
          </div>
        </div>
      </div>
    );
  };

  const user = SystemStoreUser((state) => state.user);

  return (
    <div
      className=" relative font-NotoSansThai"
      // style={{
      //   // position: "fixed",
      //   height: "100vh",
      // }}
    >
      {/* Routes nest inside one another. Nested route paths build upon
    parent route paths, and nested route elements render inside
    parent route elements. See the note about <Outlet> below. */}
      {isMobile ? <NavbarMobile /> : <Navbar />}

      <div className=" ">
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route
              path="test"
              element={
                //<SystemsProtectedRoute user={user}>
                <Test />
                // </SystemsProtectedRoute>
              }
            />
            <Route
              path="menu"
              element={
                // <SystemsProtectedRoute user={user}>
                <Menu />
                // </SystemsProtectedRoute>
              }
            />
            <Route
              path="learn"
              element={
                // <SystemsProtectedRoute user={user}>
                <Learn />
                // </SystemsProtectedRoute>
              }
            />
            <Route
              path="score"
              element={
                // <SystemsProtectedRoute user={user}>
                <Score />
                // </SystemsProtectedRoute>
              }
            />
            <Route
              path="lesson"
              element={
                // <SystemsProtectedRoute user={user}>
                <Lesson />
                // </SystemsProtectedRoute>
              }
            />
            <Route
              path="viewscore"
              element={
                // <SystemsProtectedRoute user={user}>
                <Viewscore />
                // </SystemsProtectedRoute>
              }
            />
            <Route
              path="Faq"
              element={
                // <SystemsProtectedRoute user={user}>
                <Faq />
                // </SystemsProtectedRoute>
              }
            />
            <Route index element={<Landing />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
