import { BASE_PAGE, BASE_PAGE_UNMOVE } from "./constant";

const Loading = () => {
  return (
    <div
      style={{
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // marginBottom: "-60px",
      }}
      className={BASE_PAGE}
    >
      <div
        className="absolute top-1/2 left-1/2 md:left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full
"
      >
        <img src="/images/llogodms.png" className="w-36 mx-auto my-4"></img>
        <div className="flex justify-center ">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
              className=" w-10 "
            >
              <g
                fill="none"
                stroke="#3c527c"
                stroke-linecap="round"
                stroke-width="2"
              >
                <path
                  stroke-dasharray="60"
                  stroke-dashoffset="60"
                  stroke-opacity=".3"
                  d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
                >
                  <animate
                    fill="freeze"
                    attributeName="stroke-dashoffset"
                    dur="1.3s"
                    values="60;0"
                  />
                </path>
                <path
                  stroke-dasharray="15"
                  stroke-dashoffset="15"
                  d="M12 3C16.9706 3 21 7.02944 21 12"
                >
                  <animate
                    fill="freeze"
                    attributeName="stroke-dashoffset"
                    dur="0.3s"
                    values="15;0"
                  />
                  <animateTransform
                    attributeName="transform"
                    dur="1.5s"
                    repeatCount="indefinite"
                    type="rotate"
                    values="0 12 12;360 12 12"
                  />
                </path>
              </g>
            </svg>
          </div>
          <div className="text-base text-dms-bottontest my-4 mx-2">
            loading...
          </div>
        </div>
      </div>
    </div>
  );
};
export default Loading;
