import React, { useEffect, useState } from "react";
// import BreadCrumb from "../../components/Attribute/BreadCrumb";
// import { PaginationRounded } from "../../components/Pagination";
import { FinishExam, TestExam } from "../../components/TestExam";
import { BASE_PAGE, CornerLogo } from "../../utils/constant";
import { mock_up_exam } from "../../utils/mockData";
import api from "../../api";
import Loading from "../../utils/loading";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";

const Test = () => {
  const [exam, setExam] = useState([]);
  const [finish, setFinish] = useState(false);
  const [loading, setLoading] = useState(true);
  const [point, setPoint] = useState(0);

  const user = SystemStoreUser((state) => state.user);
  console.log("finish: ", finish);

  // useEffect(() => {
  //   setExam(
  //     mock_up_exam.map((e) => {
  //       return {
  //         ...e,
  //         selected: false,
  //         choose: "",
  //       };
  //     })
  //   );
  // }, []);

  useEffect(() => {
    loadExam();
    // setExam(
    //   exam.map((e) => {
    //     return {
    //       ...e,
    //       selected: false,
    //       choose: "",
    //     };
    //   })
    // );
  }, []);

  const id_exam = user?.pre_test
    ? "63bfda6954b15290e9a845fa"
    : "63bfda6954b15290e9a845da";

  const loadExam = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get(`/exam/${id_exam}`);

      let exams = responseData?.exam?.exam;
      if (exams) {
        exams = exams?.map((exam, index) => {
          return {
            ...exam,
            selected: false,
            choice:
              exam?.awnser?.length > 0 &&
              exam?.awnser?.map((thisAwnser, index) => {
                return {
                  choice: String(index + 1),
                  answer: thisAwnser?.choice,
                  // type: exam.correct === thisAwnser.answer,
                };
              }),
          };
        });
        setExam(exams);
      }
    } catch (error) {
      console.error("ERROR TO LOAD TEST :", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        {" "}
        <Loading />
      </div>
    );
  }

  return (
    <>
      {finish ? (
        <div className={BASE_PAGE}>
          <div
            className="   
"
          >
            <div className="">
              <FinishExam exam={exam} point={point} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="   
         "
        >
          <div className="">
            <TestExam
              setExam={setExam}
              exam={exam}
              finish={finish}
              setFinish={setFinish}
              setPoint={setPoint}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Test;
