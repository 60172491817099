import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonSubmitController, Controllers } from "radium-omnibus";
import api from "../../api";
import { BASE_PAGE } from "../../utils/constant";
import FieldErrorRequired from "../../utils/customError";
import Select from "react-select";
import CSVReader from "react-csv-reader";
import { mockHospital } from "../../utils/hotpital";
import FunctionHome from "../../utils/home";
import { Landing } from "../landing";
import FieldUploadCsv from "../../utils/FieldUploadCSV";
import { toTimestamp } from "../../utils/useFunction";
const mockprefix = [
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
  { value: "อื่นๆ", label: "อื่นๆ" },
];

const mockhospital_level = [
  { value: "A", label: "A" },
  { value: "S", label: "S" },
  { value: "M1", label: "M1" },
  { value: "M2", label: "M2" },
  { value: "F1", label: "F1" },
  { value: "F2", label: "F2" },
  { value: "F3", label: "F3" },
  { value: "รพ.สต", label: "รพ.สต" },
  //   { value: "อื่นๆ", label: "อื่นๆ" },
];

const mockcounty = [
  { value: "1", label: "เขตสุขภาพที่ 1" },
  { value: "2", label: "เขตสุขภาพที่ 2" },
  { value: "3", label: "เขตสุขภาพที่ 3" },
  { value: "4", label: "เขตสุขภาพที่ 4" },
  { value: "5", label: "เขตสุขภาพที่ 5" },
  { value: "6", label: "เขตสุขภาพที่ 6" },
  { value: "7", label: "เขตสุขภาพที่ 7" },
  { value: "8", label: "เขตสุขภาพที่ 8" },
  { value: "9", label: "เขตสุขภาพที่ 9" },
  { value: "10", label: "เขตสุขภาพที่ 10" },
  { value: "11", label: "เขตสุขภาพที่ 11" },
  { value: "12", label: "เขตสุขภาพที่ 12" },
  { value: "13", label: "เขตสุขภาพที่ 13" },
];

const mockprovince = [
  { value: "กรุงเทพมหานคร", label: "กรุงเทพมหานคร", ref_dis: "13" },
  { value: "กระบี่", label: "กระบี่", ref_dis: "11" },
  { value: "กาญจนบุรี", label: "กาญจนบุรี", ref_dis: "5" },
  { value: "กาฬสินธุ์", label: "กาฬสินธุ์", ref_dis: "7" },
  { value: "กำแพงเพชร", label: "กำแพงเพชร", ref_dis: "3" },
  { value: "ขอนแก่น", label: "ขอนแก่น", ref_dis: "7" },
  { value: "จันทบุรี", label: "จันทบุรี", ref_dis: "6" },
  { value: "ฉะเชิงเทรา", label: "ฉะเชิงเทรา", ref_dis: "6" },
  { value: "ชลบุรี", label: "ชลบุรี", ref_dis: "6" },
  { value: "ชัยนาท", label: "ชัยนาท", ref_dis: "3" },
  { value: "ชัยภูมิ", label: "ชัยภูมิ", ref_dis: "9" },
  { value: "ชุมพร", label: "ชุมพร", ref_dis: "11" },
  { value: "เชียงราย", label: "เชียงราย", ref_dis: "1" },
  { value: "เชียงใหม่", label: "เชียงใหม่", ref_dis: "1" },
  { value: "ตรัง", label: "ตรัง", ref_dis: "12" },
  { value: "ตราด", label: "ตราด", ref_dis: "6" },
  { value: "ตาก", label: "ตาก", ref_dis: "2" },
  { value: "นครนายก", label: "นครนายก", ref_dis: "4" },
  { value: "นครปฐม", label: "นครปฐม", ref_dis: "5" },
  { value: "นครพนม", label: "นครพนม", ref_dis: "8" },
  { value: "นครราชสีมา", label: "นครราชสีมา", ref_dis: "9" },
  { value: "นครศรีธรรมราช", label: "นครศรีธรรมราช", ref_dis: "11" },
  { value: "นครสวรรค์", label: "นครสวรรค์", ref_dis: "3" },
  { value: "นนทบุรี", label: "นนทบุรี", ref_dis: "4" },
  { value: "นราธิวาส", label: "นราธิวาส", ref_dis: "12" },
  { value: "น่าน", label: "น่าน", ref_dis: "1" },
  { value: "หนองคาย", label: "หนองคาย", ref_dis: "8" },
  { value: "หนองบัวลำภู", label: "หนองบัวลำภู", ref_dis: "8" },
  { value: "บึงกาฬ", label: "บึงกาฬ", ref_dis: "8" },
  { value: "บุรีรัมย์", label: "บุรีรัมย์", ref_dis: "9" },
  { value: "ปทุมธานี", label: "ปทุมธานี", ref_dis: "4" },
  { value: "ประจวบคีรีขันธ์", label: "ประจวบคีรีขันธ์", ref_dis: "5" },
  { value: "ปราจีนบุรี", label: "ปราจีนบุรี", ref_dis: "6" },
  { value: "ปัตตานี", label: "ปัตตานี", ref_dis: "12" },
  { value: "พระนครศรีอยุธยา", label: "พระนครศรีอยุธยา", ref_dis: "4" },
  { value: "พังงา", label: "พังงา", ref_dis: "11" },
  { value: "พัทลุง", label: "พัทลุง", ref_dis: "12" },
  { value: "พิจิตร", label: "พิจิตร", ref_dis: "3" },
  { value: "พิษณุโลก", label: "พิษณุโลก", ref_dis: "2" },
  { value: "เพชรบุรี", label: "เพชรบุรี", ref_dis: "5" },
  { value: "เพชรบูรณ์", label: "เพชรบูรณ์", ref_dis: "2" },
  { value: "แพร่", label: "แพร่", ref_dis: "1" },
  { value: "พะเยา", label: "พะเยา", ref_dis: "1" },
  { value: "ภูเก็ต", label: "ภูเก็ต", ref_dis: "11" },
  { value: "มหาสารคาม", label: "มหาสารคาม", ref_dis: "7" },
  { value: "แม่ฮ่องสอน", label: "แม่ฮ่องสอน", ref_dis: "1" },
  { value: "มุกดาหาร", label: "มุกดาหาร", ref_dis: "10" },
  { value: "ยะลา", label: "ยะลา", ref_dis: "12" },
  { value: "ยโสธร", label: "ยโสธร", ref_dis: "10" },
  { value: "ร้อยเอ็ด", label: "ร้อยเอ็ด", ref_dis: "7" },
  { value: "ระนอง", label: "ระนอง", ref_dis: "11" },
  { value: "ระยอง", label: "ระยอง", ref_dis: "6" },
  { value: "ราชบุรี", label: "ราชบุรี", ref_dis: "5" },
  { value: "ลพบุรี", label: "ลพบุรี", ref_dis: "4" },
  { value: "ลำปาง", label: "ลำปาง", ref_dis: "1" },
  { value: "ลำพูน", label: "ลำพูน", ref_dis: "1" },
  { value: "เลย", label: "เลย", ref_dis: "8" },
  { value: "ศรีสะเกษ", label: "ศรีสะเกษ", ref_dis: "10" },
  { value: "สกลนคร", label: "สกลนคร", ref_dis: "8" },
  { value: "สงขลา", label: "สงขลา", ref_dis: "12" },
  { value: "สตูล", label: "สตูล", ref_dis: "12" },
  { value: "สมุทรปราการ", label: "สมุทรปราการ", ref_dis: "6" },
  { value: "สมุทรสงคราม", label: "สมุทรสงคราม", ref_dis: "5" },
  { value: "สมุทรสาคร", label: "สมุทรสาคร", ref_dis: "5" },
  { value: "สระแก้ว", label: "สระแก้ว", ref_dis: "6" },
  { value: "สระบุรี", label: "สระบุรี", ref_dis: "4" },
  { value: "สิงห์บุรี", label: "สิงห์บุรี", ref_dis: "4" },
  { value: "สุโขทัย", label: "สุโขทัย", ref_dis: "2" },
  { value: "สุพรรณบุรี", label: "สุพรรณบุรี", ref_dis: "5" },
  { value: "สุราษฎร์ธานี", label: "สุราษฎร์ธานี", ref_dis: "11" },
  { value: "สุรินทร์", label: "สุรินทร์", ref_dis: "9" },
  { value: "อ่างทอง", label: "อ่างทอง", ref_dis: "4" },
  { value: "อุดรธานี", label: "อุดรธานี", ref_dis: "8" },
  { value: "อุทัยธานี", label: "อุทัยธานี", ref_dis: "3" },
  { value: "อุตรดิตถ์", label: "อุตรดิตถ์", ref_dis: "2" },
  { value: "อุบลราชธานี", label: "อุบลราชธานี", ref_dis: "10" },
  { value: "อำนาจเจริญ", label: "อำนาจเจริญ", ref_dis: "10" },
];

const Register = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loadData, setLoadData] = React.useState({});
  const [selectCountry, setSelectCountry] = React.useState(null);
  const [selectProvince, setSelectProvince] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [hospitalLevel, setHospitalLevel] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [fileInfo, setFileInfo] = React.useState({});

  React.useEffect(() => {
    FunctionLoadData();
  }, []);

  const FunctionLoadData = async () => {
    if (params?.id?.length === 24) {
      try {
        const { data } = await api.get(`/users/${params.id}`);
        setLoadData(data.user);
      } catch (error) {
        console.error("ERRRO LOAD DATA", error);
      }
    } else {
      return;
    }
  };

  const onSubmit = async (form) => {
    const isCorrect =
      selectCountry?.value &&
      selectProvince?.value &&
      hospital?.value &&
      hospitalLevel?.value;

    if (!isCorrect) {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }
    if (loadData?._id) {
      try {
        await api.put(`/users/${loadData._id}`, form);
        navigate("/login");
      } catch (error) {
        console.error("ERROR TO UPDATE USER : ", error);
      }
    } else {
      if (form?.email) {
        try {
          const id = new Date().getTime();
          const {
            data: { users: checkEmail },
          } = await api.get(`/users?email=${String(form.email).toLowerCase().trim()}`);

          if (checkEmail?.length > 0) {
            alert("อีเมลนี้ได้มีการลงทะเบียนไว้แล้ว");
            return;
          }
          await api.post("/users", {
            ...form,
            id: `${id}`,
            generation3: true,
            generation: "รุ่นที่ 3",
            email: `${String(form.email).toLowerCase().trim()}`,
            username: `${String(form.email).toLowerCase().trim()}`,
            timestamp: toTimestamp(),
            prefix:
              form.prefix !== "อื่นๆ"
                ? `${String(form.prefix).toLowerCase().trim()}`
                : form.other_prefix,
            name_surname: `${String(form.name_surname).toLowerCase().trim()}`,
            job_position: `${String(form.job_position).toLowerCase().trim()}`,
            responsible_work: `${String(form.responsible_work)
              .toLowerCase()
              .trim()}`,

            hospital_level:
              hospitalLevel?.value !== "other_hospital_level"
                ? `${String(hospitalLevel?.value).toLowerCase().trim()}`
                : form.other_hospital_level,
            location:
              hospital?.value !== "other_location"
                ? `${String(hospital?.value).toLowerCase().trim()}`
                : form.other_location,
            province: `${String(selectProvince?.value).toLowerCase().trim()}`,
            county: `${String(selectCountry?.value).toLowerCase().trim()}`,
            telephone_number: `${String(form.telephone_number)
              .toLowerCase()
              .trim()}`,
          });

          // const {
          //   data: { users: checkEmail },
          // } = await api.get(`/users?email=${form.email}`);

          // console.log("checkEmail  :", checkEmail);

          // if (checkEmail?.length > 0) {
          //   alert("อีเมลนี้ได้มีการลงทะเบียนไว้แล้ว");
          //   return;
          // }

          navigate("/");
        } catch (error) {
          console.log("ERROR TO CREATE USER : ", error);
        }
      }
    }
  };

  const onClickImport = async (Data, setLoading) => {
    setLoading(true);
    try {
      console.log("Data : ", Data);

      Data?.forEach(async (row, index) => {
        try {
          const {
            data: { users: checkEmail },
          } = await api.get(
            `/users?email=${row?.email?.toLowerCase()?.trim()}`
          );

          console.log("checkEmail", checkEmail);
          if (checkEmail?.length > 0) {
            await api.put(`/users/${checkEmail[0]._id}`, {
              // username:
              //   `${String(row?.email)?.toLowerCase()?.trim()}` || "Error",
              // email: `${String(row?.email)?.toLowerCase()?.trim()}` || "Error",
              // timestamp:
              //   `${String(row?.timestamp)?.toLowerCase()?.trim()}` || "Error",
              generation: `รุ่นที่ 3`,
              generation3: true,
            });
          }
        } catch (error) {
          console.log("ERROR IN LOOP : ", { index, error });
        }
      });
    } catch (error) {
      console.log("Error : ", error);
    } finally {
      setLoading(false);
      alert("Success");
    }
  };

  const customStyles_2 = {
    control: (provided, state) => ({
      ...provided,
      color: "#35B6FC",
      backgroundColor: "#ffff",
      borderRadius: 6,
      border: "1px solid #C0C1C1",
      minHeight: "36px",
      // height: "36px",
      boxShadow: "none",
      fontSize: 12,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "36px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
    }),
  };

  const customStyles = {
    // control: (provided, state) => ({
    //   ...provided,
    //   color: "#35B6FC",
    //   backgroundColor: "#C0C1C1",
    //   borderRadius: 8,
    //   border: "1px solid #C0C1C1",
    //   minHeight: "36px",
    //   height: "36px",
    //   boxShadow: "none",
    //   fontSize: 12,
    // }),

    control: (provided, state) => ({
      ...provided,
      color: "#35B6FC",
      backgroundColor: "#ffff",
      borderRadius: 6,
      border: "1px solid #8E3A88",
      minHeight: "36px",
      height: "36px",
      boxShadow: "none",
      fontSize: 14,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "36px",
      padding: "0 6px",
      // border: "4px solid #C0C1C1",
    }),

    // input: (provided, state) => ({
    //   ...provided,
    //   margin: "0px",
    // }),
    // indicatorSeparator: (state) => ({
    //   display: "none",
    // }),
    // indicatorsContainer: (provided, state) => ({
    //   ...provided,
    //   height: "36px",
    // }),
  };

  const importFiles = async (importDatas, fileInfo) => {
    try {
      console.log("importDatas", importDatas);
    } catch (error) {
      alert(error);
    }
  };

  const importOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };
  // selectProvince && selectCountry && hospital

  // console.log("selectProvince", selectProvince);
  // console.log("selectCountry", selectCountry);
  // console.log("hospital", hospital);

  const STYLE_LABEL =
    "text-xs md:text-lg font-semibold text-dms-2textblue text-left md:-mx-4";
  const STYLE_DOK_JAN = "text-red-500 text-right md:mx-2 text-[28px]";
  const STYLE_WRAPPER = "flex items-center h-9 my-2";
  return (
    <div className="">
      <div
        style={{
          backgroundPosition: "center",

          height: "130vh",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          marginBottom: "-60px",
        }}
        className={BASE_PAGE}
      >
        <div
          onClick={() => navigate("/login")}
          className="flex items-center cursor-pointer px-0 md:px-5 py-5 "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
            className="w-6 md:w-6 lg:w-6 xl:w-6 h-6 md:h-6 lg:h-6 xl:h-6 mb-1 md:mb-0"
          >
            <path fill="#15496D" d="M10 20v-6h4v6h5v-8h3L12 3L2 12h3v8z" />
          </svg>
          <div className="flex cursor-pointer hover:text-dms-bluetext text-dms-2textblue  text-xl font-Kanit font-medium rounded-xl mx-2">
            หน้าหลัก
          </div>
        </div>
        <div
          className="  font-DBAdmanX font-semibold p-4 w-6/12 md:w-5/12 lg:w-4/12 xl:w-7/12 pt-10 md:pt-20  lg:pt-20 xl:pt-2 text-dms-2textblue text-3xl md:text-5xl lg:text-5xl xl:text-4xl 
        -mt-14 md:-mt-16 lg:-mt-6 xl:-mt-6"
        >
          ลงทะเบียน
        </div>
        <div
          // className="px-4 md:px-20 xl:px-[100px]"
          className=" bg-white rounded-2xl border-2 border-dms-2purplelight w-full md:w-full lg:w-full xl:w-8/12 
          h-[80%]   opacity-90 mx-auto "
        >
          <Controllers
            loadData={loadData}
            onSubmit={loadData._id ? false : onSubmit}
            showSymbol={false}
            showError={false}
            classNameSubmit={"hidden"}
            // classNameSubmit=" bg-blue-500  text-center p-2  text-white rounded-md hover:bg-bglogindarkbrown mb-20 duration-300 cursor-pointer  text-xl"
            classNameCancel=" bg-gray-300 border border-bghidebrown text-center p-2 text-bghidebrown rounded-md 
            hover:bg-bghidebrown hover:text-white duration-300 cursor-pointer my-2 text-xl "
            // classNameWrapper=" mx-auto"
            classNameSuperCore=" grid grid-cols-12   p-2 md:p-6 gap-x-1 md:gap-x-4 items-center "
            // onCancel={() => {
            //   navigate("/admin");
            // }}
            fields={[
              {
                type: "none",
                classNameCore: "col-span-1",
              },
              {
                type: "none",
                label: "คำนำหน้า",
                classNameCore: "  text-sm col-span-2 ",
                classNameLabel: STYLE_LABEL,
              },

              {
                type: "customs",
                name: "prefix",
                classNameCore: "col-span-9  ",
                required: true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <Select
                      // style={customStyles_2}

                      styles={customStyles}
                      options={mockprefix}
                      value={value}
                      className="  font-Kanit  font-normal w-full h-9 my-2 text-left text-sm  "
                      classNamePrefix="select"
                      placeholder="อื่นๆ"
                      isClearable={true}
                      onChange={(event) => {
                        setValue(event);
                        setValue(event);
                      }}
                    />
                  );
                },
              },
              {
                type: "none",
                classNameCore: "col-span-1",
              },
              {
                type: "none",
                classNameCore: "col-span-2",
              },
              {
                type: "text",
                name: "other_prefix",
                placeholder: "โปรดระบุ",
                classNameCore: " text-sm col-span-9 w-full ",
                classNameInput:
                  " bg-white border border-dms-2purpleintensive focus:outline-none text-zinc-900 font-Kanit font-normal p-1 text-sm  w-full h-9 rounded-md text-black my-2",

                // required: loadData._id ? false : true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>ชื่อ-นามสกุล</div>,
              },

              {
                type: "text",
                name: "name_surname",
                classNameCore: "col-span-9 ",
                classNameWrapper: STYLE_WRAPPER,
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal  focus:outline-none  p-1  rounded-md text-sm w-full h-9 ",
                required: true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>อีเมล</div>,
              },
              {
                type: "text",

                classNameLabel:
                  "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
                classNameInput:
                  "border border-gray-300 focus:outline-none px-4 p-1 text-bghidebrown rounded-md my-2 w-full",
                classNameCore: "col-span-1 text-sm mt-auto md:ml-10",
              },
              {
                type: "email",
                name: "email",
                classNameWrapper: STYLE_WRAPPER,
                classNameCore: "col-span-9",
                // classNameLabel:
                //   "text-sm text-motto-blue w-full grid grid-cols-3 gap-2  font-Kanit font-medium text-dms-2textblue py-2 text-center",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal  focus:outline-none  p-1 text-bghidebrown rounded-md text-sm w-full h-9 ",
                required: true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>เบอร์โทร</div>,
              },
              {
                type: "text",
                classNameLabel:
                  "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal  focus:outline-none px-4 p-1 text-bghidebrown rounded-md w-full",
                classNameCore: "col-span-9",
                required: loadData._id ? false : true,
              },
              {
                type: "text",
                name: "telephone_number",
                classNameCore: "col-span-9",
                classNameWrapper: STYLE_WRAPPER,
                // classNameLabel:
                //   "text-sm text-motto-blue w-full grid grid-cols-3 gap-2  font-Kanit font-medium text-dms-2textblue py-2 text-center",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal focus:outline-none  p-1 text-bghidebrown rounded-md text-sm w-full h-9 ",
                required: true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>ตำแหน่ง</div>,
              },
              {
                type: "text",
                classNameCore: "col-span-9 text-sm w-full",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal  focus:outline-none  p-1 text-bghidebrown rounded-md my-2 w-full",
                required: loadData._id ? false : true,
              },
              {
                type: "text",
                name: "job_position",
                classNameCore: "col-span-9",
                classNameWrapper: STYLE_WRAPPER,
                // classNameLabel:
                //   "text-sm text-motto-blue w-full grid grid-cols-3 gap-2  font-Kanit font-medium text-dms-2textblue py-2 text-center",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal  focus:outline-none  p-1 text-bghidebrown rounded-md text-sm w-full h-9 ",
                required: true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>งานที่รับผิดชอบ</div>,
              },
              {
                type: "text",
                classNameCore: "col-span-9 text-sm  mt-auto md:ml-10",
                classNameLabel:
                  "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal focus:outline-none px-4 p-1 text-bghidebrown rounded-md  w-full",
                required: loadData._id ? false : true,
              },
              ,
              {
                type: "text",
                name: "responsible_work",
                classNameCore: "col-span-9",
                classNameWrapper: STYLE_WRAPPER,
                // classNameLabel:
                //   "text-sm text-motto-blue w-full grid grid-cols-3 gap-2  font-Kanit font-medium text-dms-2textblue py-2 text-center",
                classNameInput:
                  "my-2 bg-white border border-dms-2purpleintensive text-zinc-900 font-Kanit font-normal  focus:outline-none  p-1 text-bghidebrown rounded-md text-sm w-full h-9 ",
                required: true,
                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>เขตสุขภาพ</div>,
              },
              {
                type: "text",
                classNameLabel:
                  "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
                // classNameInput:
                //   "bg-dms-2logingray text-white border border-gray-300 focus:outline-none px-4 p-1 text-bghidebrown rounded-md my-2 w-full",
                classNameCore: "col-span-1 text-sm mt-auto  md:ml-14 ",
                required: loadData._id ? false : true,
              },
              {
                type: "customs",
                name: "county",
                // required: true,
                classNameCore: "col-span-9 ",
                fieldCustoms: ({}) => {
                  return (
                    <div>
                      <Select
                        styles={customStyles}
                        options={mockcounty}
                        value={selectCountry}
                        className=" text-bghidebrown rounded-md text-sm w-full h-9 my-2 text-left font-Kanit font-normal"
                        classNamePrefix="select"
                        placeholder=""
                        isClearable={true}
                        onChange={(event) => {
                          setSelectCountry(event);
                          setSelectProvince(null);
                          setHospital(null);
                          setHospitalLevel(null);
                        }}
                      />
                    </div>
                  );
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>จังหวัด</div>,
              },
              {
                type: "text",

                classNameLabel:
                  "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
                // classNameInput:
                //   "bg-dms-2logingray text-white border border-gray-300 focus:outline-none px-4 p-1 text-bghidebrown rounded-md my-2 w-full",
                classNameCore: "col-span-1 text-sm mt-auto  md:ml-14 ",
                required: loadData._id ? false : true,
              },
              {
                type: "customs",
                name: "province",
                // required: true,
                classNameCore: "col-span-9",
                fieldCustoms: ({}) => {
                  return (
                    <div>
                      <Select
                        styles={customStyles}
                        options={
                          selectCountry
                            ? mockprovince.filter(
                                (province) =>
                                  province.ref_dis === selectCountry.value
                              )
                            : []
                        }
                        value={selectProvince}
                        className=" text-bghidebrown rounded-md text-sm w-full h-9 my-2 text-left font-Kanit font-normal "
                        classNamePrefix="select"
                        placeholder=""
                        isClearable={true}
                        onChange={(event) => {
                          setSelectProvince(event);
                          const hospital = mockHospital.find(
                            (hospital) =>
                              String(hospital["เขตสุขภาพ"]) ===
                                selectCountry.value &&
                              String(hospital["จังหวัด"]) === event.value
                          );
                          setHospital({
                            label: hospital.Hospital,
                            value: hospital.Hospital,
                            level: hospital["ระดับรพ."],
                          });
                          setHospitalLevel(null);
                        }}
                      />
                    </div>
                  );
                },
              },

              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>โรงพยาบาล</div>,
              },
              // {
              //   type: "text",

              //   classNameCore: "col-span-8 text-sm mt-auto md:ml-10",
              //   classNameLabel:
              //     "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
              //   // classNameInput:
              //   //   "bg-dms-2logingray text-zinc-900 font-Kanit font-normal border border-gray-300 focus:outline-none px-4 p-1 text-bghidebrown rounded-md my-2 w-full",
              //   required: loadData._id ? false : true,
              // },
              {
                type: "customs",
                name: "location",
                // required: true,
                classNameCore: "col-span-9",
                fieldCustoms: ({}) => {
                  return (
                    <div className="col-span-8 text-sm mt-auto ">
                      <Select
                        styles={customStyles}
                        options={
                          selectProvince
                            ? mockHospital
                                .map((hospital) => {
                                  if (
                                    String(hospital["เขตสุขภาพ"]) ===
                                      selectCountry.value &&
                                    String(hospital["จังหวัด"]) ===
                                      selectProvince.value
                                  ) {
                                    return {
                                      label: hospital.Hospital,
                                      value: hospital.Hospital,
                                      level: hospital["ระดับรพ."],
                                    };
                                  }
                                })
                                .filter((hospital) => hospital)
                                .concat([
                                  {
                                    label: "อื่นๆ",
                                    value: "other_location",
                                    level: "",
                                  },
                                ])
                            : []
                        }
                        value={hospital}
                        className=" text-bghidebrown rounded-md text-sm w-full h-9 my-2 text-left font-Kanit font-normal "
                        classNamePrefix="select"
                        placeholder=""
                        isClearable={true}
                        onChange={(event) => {
                          setHospital(event);
                          setHospitalLevel(null);
                        }}
                      />
                    </div>
                  );
                },
              },

              {
                type: "none",
                classNameCore: "col-span-3",
              },
              // {
              //   type: "none",
              //   classNameCore: "col-span-1",
              //   label: <div className={STYLE_DOK_JAN}>*</div>,
              // },
              // {
              //   type: "text",
              //   name: "other_location",
              //   placeholder: "โปรดระบุ",
              //   classNameCore: " text-sm col-span-9 w-full ",
              //   classNameInput:
              //     "my-2 bg-white border border-dms-2purpleintensive focus:outline-none text-zinc-900 font-Kanit font-normal p-1 text-sm  w-full h-9 rounded-md text-black",

              //   // required: loadData._id ? false : true,
              //   disabled: loadData._id ? true : false,
              //   renderError: ({ error }) => {
              //     return <FieldErrorRequired error={error} />;
              //   },
              // },
              {
                type: "customs",
                name: "other_location",
                classNameCore: "col-span-9  ",
                required: hospital?.label === "อื่นๆ" ? true : false,

                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
                fieldCustoms: (props) => {
                  return (
                    <div className="relative  rounded-md text-black w-full -mt-4">
                      <input
                        onChange={({ target: { value } }) => {
                          props?.setValue(value);
                        }}
                        type="text"
                        id="name"
                        name="name"
                        required="required"
                        placeholder="โปรดระบุ"
                        className=" bg-white border border-dms-2purpleintensive focus:outline-none  font-Kanit font-normal p-1 text-sm  w-full h-9 rounded-md text-black placeholder:pl-3"
                      />
                      {hospital?.label === "อื่นๆ" && (
                        <label className="absolute top-9 left-2 w-fit text-sm font-normal cursor-text"></label>
                      )}
                    </div>
                  );
                },
              },
              {
                type: "none",
                classNameCore: "col-span-1",
                label: <div className={STYLE_DOK_JAN}>*</div>,
              },
              {
                type: "none",
                classNameCore: "col-span-2 ",
                label: <div className={`${STYLE_LABEL} `}>ระดับโรงพยาบาล</div>,
              },
              {
                type: "text",
                label: (
                  <div className="flex ">
                    <div className="text-red-500 text-lg md:text-3xl ">*</div>{" "}
                  </div>
                ),
                classNameLabel:
                  "text-sm md:text-xl font-Kanit font-medium text-dms-2textblue ",
                // classNameInput:
                //   "bg-dms-2logingray text-white border border-gray-300 focus:outline-none px-4 p-1 text-bghidebrown rounded-md my-2 w-full",
                classNameCore: "col-span-9 text-sm mt-auto",
                required: loadData._id ? false : true,
              },
              {
                type: "customs",
                name: "hospital_level",
                // required: true,
                classNameCore: "col-span-9  ",

                fieldCustoms: ({ value, setValue }) => {
                  return (
                    <div>
                      <div className=""></div>
                      <Select
                        styles={customStyles}
                        options={
                          selectProvince && selectCountry && hospital
                            ? mockhospital_level
                                .map((hospitalLevel) => {
                                  if (
                                    String(hospitalLevel.value) ===
                                    String(hospital.level)
                                  ) {
                                    return {
                                      label: hospitalLevel.label,
                                      value: hospitalLevel.value,
                                    };
                                  }
                                })
                                .filter((hospitalLevel) => hospitalLevel)
                                .concat([
                                  {
                                    label: "อื่นๆ",
                                    value: "other_hospital_level",
                                  },
                                ])
                            : []
                        }
                        value={hospitalLevel}
                        className=" text-bghidebrown rounded-md text-sm w-full h-9 my-2 text-left font-Kanit font-normal "
                        classNamePrefix="select"
                        placeholder=""
                        isClearable={true}
                        onChange={(event) => {
                          setHospitalLevel(event);
                          setValue(event);
                        }}
                      />
                    </div>
                  );
                },
              },

              {
                type: "none",
                classNameCore: "col-span-3",
              },
              {
                type: "customs",
                name: "other_hospital_level",
                classNameCore: "col-span-9  ",
                required: hospitalLevel?.label === "อื่นๆ" ? true : false,

                disabled: loadData._id ? true : false,
                renderError: ({ error }) => {
                  return <FieldErrorRequired error={error} />;
                },
                fieldCustoms: (props) => {
                  return (
                    <div className="relative  rounded-md text-black w-full -mt-4">
                      <input
                        onChange={({ target: { value } }) => {
                          props?.setValue(value);
                        }}
                        type="text"
                        id="name"
                        name="name"
                        required="required"
                        placeholder="โปรดระบุ"
                        className=" bg-white border border-dms-2purpleintensive focus:outline-none  font-Kanit font-normal p-1 text-sm  w-full h-9 rounded-md text-black placeholder:pl-3"
                      />
                      {hospitalLevel?.label === "อื่นๆ" && (
                        <label className="absolute top-9 left-2 w-fit text-sm font-normal cursor-text"></label>
                      )}
                    </div>
                  );
                },
              },
              // {
              //   type: "none",
              //   classNameCore: "col-span-1",
              //   label: <div className={STYLE_DOK_JAN}>*</div>,
              // },
              // {
              //   type: "text",
              //   name: "other_hospital_level",
              //   placeholder: "โปรดระบุ",
              //   classNameLabel:
              //     "absolute top-4 left-4 text-gray-400 font-normal ",
              //   classNameCore: " text-sm col-span-9 w-full relative ",
              //   classNameInput:
              //     "my-2 bg-white border border-dms-2purpleintensive focus:outline-none text-zinc-900 font-Kanit font-normal p-1 text-sm  w-full h-9 rounded-md text-black ",

              //   required: loadData._id ? false : true,
              //   disabled: loadData._id ? true : false,

              //   renderError: ({ error }) => {
              //     return <FieldErrorRequired error={error} />;
              //   },
              // },
            ]}
          />
          <div className=" flex justify-center items-center gap-6 -my-2">
            <ButtonSubmitController
              labelSubmit={
                <div className="relative">
                  <div>
                    <img
                      src="/images/ปุ่มดาวน์โหลด.png"
                      className="w-28 md:w-40 lg:w-40 xl:w-40 h-10 md:h-10 mx-auto"
                    ></img>

                    <div
                      className="absolute top-2  left-6 md:left-20 lg:left-20 xl:left-16 mx-1 md:-mx-7 lg:-mx-7 xl:-mx-4 
              font-DBAdmanX font-bold text-2xl md:text-2xl lg:text-2xl xl:text-2xl -my-1 md:-my-1 lg:-my-1 xl:-my-1
          text-slate-700 hover:text-dms-2textblue"
                    >
                      ยืนยัน
                    </div>
                  </div>
                  {/* <button className=" bg-blue-400 mt-5">import hospital</button> */}
                  {/* <CSVReader
                    parserOptions={importOptions}
                    onFileLoaded={importFiles}
                  >
                    Create
                  </CSVReader> */}
                </div>
              }
              classNameSubmit={
                "rounded-full duration-200 text-zinc-500 text-center text-sm md:text-base font-medium cursor-pointer h-10 flex  justify-center items-center w-fit "
              }
            />
            {/* <div>
              <div className="text-center border border-bccpurple w-full h-modals">
                <div className=" p-4 border border-b-bccpurple ">
                  Import / Export
                </div>
                <div className="p-4 border border-b-bccpurple ">
                  Filename : {fileInfo?.name}
                </div>
                <div className="p-4 border border-b-bccpurple ">
                  Size : {fileInfo?.size}
                </div>
                <div className="my-4">Total : {data?.length}</div>
                {loading ? (
                  <div>กำลังโหลดโปรดรอสักครู่...</div>
                ) : (
                  <React.Fragment>
                    <div className="mx-auto">
                      <FieldUploadCsv
                        setData={setData}
                        setFileInfo={setFileInfo}
                      />
                    </div>

                    {data?.length > 0 && (
                      <div>
                        <div
                          onClick={() => onClickImport(data, setLoading)}
                          className="cursor-pointer w-24 p-2 bg-gray-500 text-white mx-auto my-20 rounded-xl text-center"
                        >
                          Import
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div> */}
            {/* <div
              onClick={() => onClickImport()}
              className="cursor-pointer w-72 p-2 bg-gray-500 text-white mx-auto my-20 rounded-xl text-center"
            >
              import
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
