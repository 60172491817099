import { ButtonSubmitController } from "radium-omnibus";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_PAGE, CornerLogo } from "../utils/constant";
import { STYLE_TEXT } from "./Menu";

export const Landing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login");
  }, []);

  return (
    <div
      style={{
        backgroundPosition: "center",

        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "-60px",
      }}
      className={BASE_PAGE}
    >
      <div className=" max-w-[1440px] mx-auto">
        {/* <div className="font-DBAdmanX font-semibold w-full pt-10 text-dms-bluetext md:text-4xl lg:text-5xl xl:text-6xl">
          ประชุมเชิงปฏิบัติการพัฒนาศักยภาพบุคลากร
        </div>
        <div className="font-DBAdmanX font-semibold w-full md:mt-2 lg:mt-2 xl:mt-2 text-dms-bluetext md:text-4xl lg:text-5xl xl:text-6xl">
          เพื่อการจัดตั้งคลินิกผู้สูงอายุ
        </div>
        <div className="font-DBAdmanX md:w-6/12 lg:w-5/12 xl:w-4/12 text-white p-2 md:text-2xl lg:text-3xl xl:text-4xl mx-auto mt-10 font-light rounded-full bg-gradient-to-b from-dms-gradientt to-dms-gradientb  ">
          รุ่นที่ 2 วันที่ 1-3 กุมภาพันธ์ 2566
        </div>
        <div className=" max-w-2xl xl:max-w-5xl mx-auto">
          <div className="flex items-center mt-10 ">
            <img
              src="images/one.png"
              className="    md:w-20 lg:w-20 xl:w-24 md:h-20 lg:h-20 xl:h-24"
            ></img>
            <div className="bg-white w-1 md:h-10 lg:h-14 xl:h-16 mx-2 "></div>

            <div className="  text-left md:text-2xl lg:text-3xl xl:text-4xl text-dms-graytext font-DBAdmanX font-semibold">
              1-3 กุมภาพันธ์ 2566
              <div className="grid-flow-col">08.00 น. - 16.30 น.</div>{" "}
            </div>
          </div>
          <div className="flex items-center mt-3 ">
            <img
              src="images/two.png"
              className="md:w-20 lg:w-20 xl:w-24 md:h-20 lg:h-20 xl:h-24"
            ></img>
            <div className="bg-white w-1 md:h-10 lg:h-14 xl:h-16 mx-2 "></div>
            <div className="text-left md:text-2xl lg:text-3xl xl:text-4xl text-dms-graytext font-DBAdmanX font-semibold">
              ณ ห้องประชุมสถาบันเวชศาสตร์
              สมเด็จพระสังฆราชญาณสังวรเพื่อผู้สูงอายุ
              <div className="grid-flow-col ">จังหวัดนนทบุรี</div>
            </div>
          </div>
        </div> */}
        <div className=" flex justify-center items-center gap-2 w-full ">
          <div
            className="bg-white rounded-xl border-2 border-dms-2purplelight w-full md:w-8/12 lg:w-6/12 xl:w-2/5 
        h-64 md:h-64 lg:h-72 xl:h-64  opacity-90 mx-auto my-40"
          >
            {/* <div className="text-dms-2textblue text-xl md:text-3xl font-Kanit font-medium  mt-24 md:mt-24 lg:mt-28 xl:mt-24">
              ขอบคุณสำหรับการลงทะเบียน
            </div> */}
            <div
              onClick={() => navigate("/login")}
              className="relative mt-24 cursor-pointer "
            >
              <ButtonSubmitController
                labelSubmit={
                  <div className={` relative z-20 `}>
                    <div
                      className={`w-44 md:w-56 py-1  bg-[#FAEA48] rounded-full mx-auto `}
                    >
                      {/* <img
                    src="/images/ปุ่มดาวน์โหลด.png"
                    className="w-28 md:w-40 lg:w-40 xl:w-48 h-10 md:h-12 mx-auto"
                  ></img> */}

                      <div className={STYLE_TEXT}>เข้าสู่ระบบ</div>
                    </div>
                    <div
                      className={`w-44 md:w-56 py-1 bg-[#125B7F] text-[#125B7F] rounded-full mx-auto absolute top-[10%] left-[51%] -translate-x-1/2 -z-10`}
                    >
                      <div className={` text-2xl md:text-3xl text-[#15496E] `}>
                        .
                      </div>
                    </div>
                  </div>
                }
                classNameSubmit="  flex justify-center items-center w-fit mx-auto mt-4"
              />

              {/* <div>
                <img
                  src="/images/ปุ่มดาวน์โหลด.png"
                  className="w-44 md:w-60 h-12 md:h-16 mx-auto my-24"
                ></img>

                <div
                  className="absolute top-2  left-36 md:left-48 lg:left-42 xl:left-60 -mx-2 md:-mx-6 lg:-mx-5 xl:-mx-8 
              font-Kanit font-medium text-xl md:text-3xl lg:text-3xl xl:text-3xl 
          text-slate-700 hover:text-dms-2textblue"
                >
                  เข้าสู่ระบบ
                </div>
              </div> */}
            </div>

            {/* <div className="-my-6">
            {" "}
            <div className="flex justify-end  ">
              <div className="bg-white md:w-24 lg:w-36 xl:w-36 md:h-24 lg:h-36 xl:h-36 mb-2 mx-auto ">
                <img
                  src="images/แบบตอบรับอบรมbasic.png"
                  className="md:w-32 lg:w-44 xl:w-44 p-2"
                ></img>
              </div>
              <div className="my-4">
                <img
                  src="images/zoomm.png"
                  className="md:w-32 lg:w-44 xl:w-44"
                ></img>
              </div>
            </div>
            <div className="flex justify-end ">
              <div className="  md:py-1 lg:py-1 xl:py-1  md:mt-2 lg:mt-0 xl:mt-0 md:w-56 lg:w-80 xl:w-80 text-dms-bottontest  md:text-3xl lg:text-4xl xl:text-4xl  font-DBAdmanX font-medium rounded-full  bg-gradient-to-b from-dms-gradientt to-dms-gradientb">
                สแกนเพื่อลงทะเบียน
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
      {/* <CornerLogo /> */}
    </div>
  );
};
