import { useEffect, useState } from "react";
import { BASE_PAGE } from "../../utils/constant";
import Select from "react-select";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import {
  ButtonSubmitController,
  Controllers,
  FunctionUseParams,
} from "radium-omnibus";
import FieldErrorRequired from "../../utils/customError";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import {
  STYLE_BUTTON,
  STYLE_BUTTON2,
  STYLE_RELATIVE_PARENT,
  STYLE_TEXT,
} from "../Menu";
import SearchBar from "../../utils/searchbar";
import FunctionHome from "../../utils/home";

export const FAQ_SECTION =
  "text-dms-2textblue text-lg font-Kanit font-semibold";
export const FAQ_QA =
  "text-dms-2textblue text-sm md:text-base font-Kanit font-normal";
export const FAQ_QAMORE =
  "text-dms-2textbluee text-base font-Kanit font-semibold";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log("faqs", faqs);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [loadData, setLoadData] = useState({});
  const navigate = useNavigate();
  const user = SystemStoreUser((state) => state.user);
  const [searched, setSearched] = useState("");

  console.log("user", user);

  useEffect(() => {
    setTitle("บทเรียนที่ ");
  }, []);
  const data = [
    {
      question: "ไก่กับไข่อะไรเกิดก่อนกัน",
      ans: "ไข่กับไก่",
    },
    {
      question: "ไก่กับไข่อะไรเกิดก่อนกัน",
      ans: "ไข่กับไก่",
    },
    {
      question: "ไก่กับไข่อะไรเกิดก่อนกัน",
      ans: "ไข่กับไก่",
    },
  ];

  const from_lesson = [
    { value: "บทเรียนที่ 1", label: "บทเรียนที่ 1" },
    { value: "บทเรียนที่ 2", label: "บทเรียนที่ 2" },
    { value: "บทเรียนที่ 3", label: "บทเรียนที่ 3" },
    { value: "บทเรียนที่ 4", label: "บทเรียนที่ 4" },
    { value: "บทเรียนที่ 5", label: "บทเรียนที่ 5" },
    { value: "บทเรียนที่ 6", label: "บทเรียนที่ 6" },
    { value: "บทเรียนที่ 7", label: "บทเรียนที่ 7" },
    { value: "บทเรียนที่ 8", label: "บทเรียนที่ 8" },
    { value: "บทเรียนที่ 9", label: "บทเรียนที่ 9" },
    { value: "บทเรียนที่ 10", label: "บทเรียนที่ 10" },
    { value: "บทเรียนที่ 11", label: "บทเรียนที่ 11" },
    { value: "บทเรียนที่ 12", label: "บทเรียนที่ 12" },
    { value: "บทเรียนที่ 13", label: "บทเรียนที่ 13" },
    { value: "บทเรียนที่ 14", label: "บทเรียนที่ 14" },
    { value: "บทเรียนที่ 15", label: "บทเรียนที่ 15" },
    { value: "บทเรียนที่ 16", label: "บทเรียนที่ 16" },
  ];

  useEffect(() => {
    loadFaqs();
  }, [searched]);

  // const loadFaqs = async () => {
  //   try {
  //     setLoading(true);
  //     const { data: responseData } = await api.get("/faqs?limit=4");
  //     console.log("responseData", responseData);
  //     setFaqs(responseData?.faqs);
  //   } catch (error) {
  //     console.log("ERROR TO LOAD FAQ :", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const loadFaqs = async () => {
    const thisParams = FunctionUseParams({
      limit: 4,
      search: searched,
      has_answer: 1,
    });
    try {
      setLoading(true);
      const { data: responseData } = await api.get(`/faqs` + thisParams);
      console.log("loadFaqs", data);
      setFaqs(responseData?.faqs);
    } catch (error) {
      console.log("ERROR TO LOAD FAQ :", error);
    }
  };

  const onSubmit = async (form) => {
    console.log("form : ", { form, loadData });
    try {
      await api.post("/faqs", {
        ...form,
        user_id: user._id,
        email: `${String(user.email)}`,
        ask: `${String(form.ask)}`,
        answer: `${String("")}`,
        // from_lesson: `${String(form.from_lesson)}`,
        // name_surname: `${String(form.name_surname)}`,
      });
      alert("ส่งคำถามแล้ว");
      navigate("/menu");
    } catch (error) {
      console.log("ERROR TO CREATE USER : ", error);
    }
  };

  return (
    <div
      style={{
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        marginBottom: "-60px",
      }}
      className={`${BASE_PAGE} `}
    >
      <FunctionHome />
      {/* <SearchBar /> */}
      <div className="text-left px-0 md:px-10 lg:px-20 xl:px-40 p-6 -my-14 md:-my-10">
        <div>
          <div className="md:flex mt-4 md:mt-0">
            <div className={FAQ_SECTION}>คำถามที่พบบ่อย</div>

            <div className="flex bg-dms-2textbluelight w-full md:w-80  border border-dms-2textblue rounded-md md:mx-2">
              <input
                value={searched}
                className="w-full md:w-80  py-2  h-10 text-base text-dms-2textblue font-medium focus:outline-none rounded-l-md p-1 "
                onChange={(e) => setSearched(e.target.value)}
                placeholder="ค้นหา..."
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 16 16"
                className="my-auto mx-2"
              >
                <path
                  fill="#15496D"
                  d="M10.68 11.74a6 6 0 0 1-7.922-8.982a6 6 0 0 1 8.982 7.922l3.04 3.04a.749.749 0 0 1-.326 1.275a.749.749 0 0 1-.734-.215ZM11.5 7a4.499 4.499 0 1 0-8.997 0A4.499 4.499 0 0 0 11.5 7Z"
                />
              </svg>
            </div>
          </div>
          {faqs?.map((faqs) => {
            return (
              <div className="mt-2">
                <div className={FAQ_QA}>คำถาม : {faqs?.ask}</div>
                <div className={FAQ_QA}>คำตอบ : {faqs?.answer}</div>
                <div className="bg-dms-2textbluee w-full  h-0.5"></div>
              </div>
            );
          })}
        </div>
        {/* <div className="mt-2">
          <div className={FAQ_QAMORE}>
            คำถามเพิ่มเติม
            <div className="bg-dms-2textbluee w-24 md:w-24 lg:w-24 xl:w-28 h-0.5 "></div>
          </div>
        </div> */}
        {/* <div className={`${FAQ_SECTION}` + ""}>คำถามเพิ่มเติม</div> */}
        <div className="text-dms-2textblue text-lg font-Kanit font-medium mt-4 ">
          พิมพ์คำถามที่ต้องการทราบในช่องนี้
        </div>
        <div
          className="w-full md:w-52 lg:w-56 xl:w-72  cursor-pointer 
        text-base md:text-sm lg:text-base xl:text-base"
        >
          {/* <Select
            placeholder={
              <div className=" text-dms-2textblue font-Kanit font-medium text-xl md:text-base lg:text-xl xl:text-base">
                บทเรียนที่ 1
              </div>
            }
            options={[
              {
                label: (
                  <div className="text-dms-2textblue font-Kanit font-medium">
                    บทเรียนที่ 1
                  </div>
                ),
              },
              {
                label: (
                  <div className="text-dms-2textblue font-Kanit font-medium">
                    บทเรียนที่ 2
                  </div>
                ),
              },
            ]}
          /> */}
        </div>
        <Controllers
          showSymbol={false}
          showError={false}
          loadData={loadData}
          onSubmit={loadData._id ? false : onSubmit}
          classNameSubmit={"hidden"}
          fields={[
            {
              type: "text",
              name: "ask",
              // classNameCore: "col-span-8",
              // classNameLabel:
              //   "text-sm text-motto-blue w-full grid grid-cols-3 gap-2  font-Kanit font-medium text-dms-2textblue py-2 text-center",
              classNameInput:
                "bg-white  border-2 border-dms-2purplelight  rounded-xl  w-full h-40 md:h-60  mt-4 text-zinc-400 font-Kanit font-medium text-xl md:text-base lg:text-xl xl:text-lg p-4",
              required: true,
              disabled: loadData._id ? true : false,
              renderError: ({ error }) => {
                return <FieldErrorRequired error={error} />;
              },
            },
          ]}
        />

        <div
          // onClick={() => navigate("")}
          className=" "
        >
          <ButtonSubmitController
            labelSubmit={
              <div className={` relative z-20 `}>
                <div
                  className={`w-44 md:w-56 py-1  bg-[#FAEA48] rounded-full mx-auto `}
                >
                  {/* <img
                    src="/images/ปุ่มดาวน์โหลด.png"
                    className="w-28 md:w-40 lg:w-40 xl:w-48 h-10 md:h-12 mx-auto"
                  ></img> */}

                  <div className={STYLE_TEXT}>ยืนยัน</div>
                </div>
                <div
                  className={`w-44 md:w-56 py-1 bg-[#125B7F] text-[#125B7F] rounded-full mx-auto absolute top-[10%] left-[51%] -translate-x-1/2 -z-10`}
                >
                  <div className={` text-2xl md:text-3xl text-[#15496E] `}>
                    .
                  </div>
                </div>
              </div>
            }
            classNameSubmit="  flex justify-center items-center w-fit mx-auto mt-4"
          />
          <div>
            {/* <div className="text-dms-2textblue text-base font-Kanit font-medium text-center mt-4">
              "ทีมงานจะตอบคำถามทุกวันศุกร์ ทาง e mail ที่ท่านลงทะเบียนไว้"
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
