import create from "zustand";
import { persist } from "zustand/middleware";

export const SystemStoreUser = create(
  persist(
    (set, get) => ({
      user: null,
      setUser: (user) => set(() => ({ user })),
      clearUser: () => set(() => ({ user: null })),
    }),
    {
      name: "user",
      getStorage: () => sessionStorage,
    }
  )
);

export const FunctionGetTokenFromLocalStorage = () => {
  const access_token = sessionStorage.getItem("access_token");
  const expires_in = sessionStorage.getItem("expires_in");
  const refresh_token = sessionStorage.getItem("refresh_token");
  const refresh_expires_in = sessionStorage.getItem("refresh_expires_in");

  return {
    access_token,
    expires_in,
    refresh_token,
    refresh_expires_in,
  };
};

export const SystemSetTokenSessionStorage = ({ data }) => {
  sessionStorage.setItem("access_token", data.access_token);
  sessionStorage.setItem("expires_in", data.expires_in);
  sessionStorage.setItem("refresh_token", data.refresh_token);
  sessionStorage.setItem("refresh_expires_in", data.refresh_expires_in);
  sessionStorage.setItem("user_role", data.user_role);
};

// export const SystemsSetStorage = async ({ data, setUser }) => {
//   SystemSetTokenSessionStorage({ data });
//   const {
//     data: { data: profile },
//   } = await api.api_authen_profile();
//   sessionStorage.setItem("user", JSON.stringify(profile));
//   setUser(profile);
// };

export const SystemLogout = async () => {
  try {
    sessionStorage.clear();
    window.location.reload();
  } catch (error) {
    sessionStorage.clear();
    window.location.reload();
  }
};
