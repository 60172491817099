import React, { useEffect, useState } from "react";
import { BASE_PAGE, CornerLogo } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import LogOut from "../../utils/logout";
import FunctionLogOut from "../../utils/logout";
import FunctionHome from "../../utils/home";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Certificate from "../../components/pdf/Certificate";
import api from "../../api";

export const checkVideoList = [
  "บทเรียนที่ 2 เกณฑ์มาตรฐานการจัดตั้งคลินิกผู้สูงอายุ",
  "บทเรียนที่ 3 การประเมินสุขภาพผู้สูงอายุ (Comprehensivegeriatric assessment-CGA) โดยบุคลากรทาง การแพทย์",
  "บทเรียนที่ 4 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Dementiaand Mild cognitive impairment",
  "บทเรียนที่ 7 กลุ่มอาการสูงอายุ (geriatric syndrome)และการจัดการในโรงพยาบาลระดับ F-A เรื่องFalling/Instability and OA",
];

export const STYLE_TEXT =
  "text-center font-semibold text-2xl md:text-3xl text-[#15496E] hover:text-dms-bluetext";
export const STYLE_BUTTON =
  " w-full md:w-64 py-3 bg-[#FAEA48] rounded-full mx-auto";
export const STYLE_BUTTON_INACTIVE =
  " w-full md:w-64 py-3 bg-[#F2F2F2] rounded-full mx-auto";
export const STYLE_BUTTON2 =
  " w-full md:w-64 py-3 bg-[#125B7F] text-[#125B7F] rounded-full mx-auto absolute top-[10%] left-[51%] -translate-x-1/2 -z-20";
export const STYLE_RELATIVE_PARENT = "relative mt-6";

const Menu = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [popup, setPopUp] = useState(false);

  const user = SystemStoreUser((state) => state.user);
  const setUser = SystemStoreUser((state) => state.setUser);

  console.log(`user: `, user);

  const largestPointPost = user?.score_post_test;

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/users/${user?._id}`);
      setUser(data?.user);
    } catch (error) {
      console.log("ERROR TO LOAD user :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // marginBottom: "-60px",
      }}
      className={`${BASE_PAGE} `}
    >
      <FunctionHome />

      <div
        className=" bg-white rounded-xl border-2 border-dms-2purplelight w-full md:w-8/12  xl:w-3/6 
        h-auto  opacity-90 mx-auto p-8 "
      >
        <div className="flex justify-center items-center gap-1 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 md:w-20"
            viewBox="0 0 24 24"
          >
            <g
              fill="none"
              stroke="#15496d"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M21 19v-6.733a4 4 0 0 0-1.245-2.9L13.378 3.31a2 2 0 0 0-2.755 0L4.245 9.367A4 4 0 0 0 3 12.267V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2Z" />
              <path d="M9 15a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v6H9v-6Z " />
            </g>
          </svg>
          <div className="text-dms-2textblue text-2xl md:text-4xl font-medium font-Kanit mt-4 md:mt-8">
            หน้าแรก
          </div>
          <img src="/images/physician.png" className=" w-6 md:w-8"></img>
        </div>

        {/* <div
          onClick={() => navigate("/test")}
          className="relative mt-10 cursor-pointer"
        >
          <div>
            <img
              src="/images/ปุ่มดาวน์โหลด.png"
              className="w-52 md:w-64 h-14 md:h-16 mx-auto"
            ></img>

            <div
              onClick={() => navigate("/test")}
              className="absolute top-2  left-24 md:left-52 lg:left-72 xl:left-72 mx-2 md:-mx-4 lg:-mx-4 xl:mx-0 
              font-DBAdmanX font-bold text-3xl md:text-3xl lg:text-3xl xl:text-4xl 
          text-slate-700 hover:text-dms-2textblue"
            >
              Pre-Test
            </div>
          </div>
        </div> */}
        {user?.pre_test ? <InActiveButtonPreTest /> : <ActiveButtonPreTest />}

        {user?.pre_test ? <ActiveButton /> : <InActiveButton />}
        {user?.pre_test ? (
          <ActiveButtonPostTest user={user} />
        ) : (
          <InActiveButtonPostTest />
        )}
        {user?.pre_test ? (
          <ActiveButtonViewscore />
        ) : (
          <InActiveButtonViewscore />
        )}

        {/* todo */}

        {/* <div
          className="relative mt-6"

          // className={
          //   user?.pre_test
          //     ? //คลิ๊กสอบได้///
          //       " relative cursor-pointer rounded-3xl opacity-90 md:h-20 lg:h-20 xl:h-24 md:w-44 lg:w-44 xl:w-60 hover:bg-dms-zoom bg-dms-button "
          //     : ///คลิ๊กไปสอบไม่ได้///
          //       "relative  rounded-3xl md:h-20 lg:h-20 xl:h-24 md:w-44 lg:w-44 xl:w-60 bg-dms-Choice "
          // }
          // onClick={() => {
          //   if (user?.pre_test === true) {
          //     navigate("/test");
          //   } else {
          //     setPopUp(true);
          //     return;
          //   }
          // }}
        >
          <div
          // className={
          //   user?.pre_test
          //     ? "absolute  font-DBAdmanX font-medium   md:left-3 lg:left-3 xl:left-6  md:top-5 lg:top-5 xl:top-6 md:text-5xl lg:text-5xl xl:text-6xl text-dms-graytext "
          //     : "absolute  font-DBAdmanX font-medium   md:left-3 lg:left-3 xl:left-6  md:top-5 lg:top-5 xl:top-6 md:text-5xl lg:text-5xl xl:text-6xl text-white"
          // }
          >
            <img
              src="/images/ปุ่มดาวน์โหลด.png"
              className="w-52 md:w-64 h-14 md:h-16 mx-auto"
            ></img>
            <div
              onClick={() => navigate("/test")}
              className="absolute   top-2   left-20 md:left-52 lg:left-72 xl:left-72 mx-5 md:-mx-5 lg:-mx-5 xl:-mx-1 font-DBAdmanX font-bold text-3xl md:text-3xl lg:text-3xl xl:text-4xl 
          text-slate-700 hover:text-dms-2textblue"
            >
              Post-Test
            </div>
          </div>
        </div> */}
        {/* รอเปิด */}
        {/* <div
          className="relative mt-6"

          
        >
          <div
          
          >
            <img
              src="/images/ปุ่มหน้าหลักสีเทา.png"
              className="w-52 md:w-64 h-14 md:h-16 mx-auto"
            ></img>
            <div
              onClick={() => navigate("/test")}
              className="absolute   top-2   left-20 md:left-52 lg:left-72 xl:left-72 mx-5 md:-mx-5 lg:-mx-5 xl:-mx-1 font-DBAdmanX font-bold text-3xl md:text-3xl lg:text-3xl xl:text-4xl 
          text-slate-700 hover:text-dms-2textblue"
            >
              Post-Test
            </div>
          </div>
        </div> */}

        <div
          className="relative mt-6"
          // className={
          //   user?.pre_test
          //     ? //คลิ๊กสอบได้///
          //       " relative cursor-pointer rounded-3xl opacity-90 md:h-20 lg:h-20 xl:h-24 md:w-44 lg:w-44 xl:w-60 hover:bg-dms-zoom bg-dms-button "
          //     : ///คลิ๊กไปสอบไม่ได้///
          //       "relative  rounded-3xl md:h-20 lg:h-20 xl:h-24 md:w-44 lg:w-44 xl:w-60 bg-dms-Choice "
          // }
        >
          {/* <div
            onClick={() => navigate("/Viewscore")}
            // className={
            //   user?.pre_test
            //     ? "absolute  font-DBAdmanX font-medium   md:left-3 lg:left-3 xl:left-6  md:top-5 lg:top-5 xl:top-6 md:text-5xl lg:text-5xl xl:text-6xl text-dms-graytext "
            //     : "absolute  font-DBAdmanX font-medium   md:left-3 lg:left-3 xl:left-6  md:top-5 lg:top-5 xl:top-6 md:text-5xl lg:text-5xl xl:text-6xl text-white"
            // }
          >
            {" "}
            <img
              src="/images/ปุ่มดาวน์โหลด.png"
              className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-pointer"
            ></img>
            <div
              className="absolute top-2  left-24 md:left-48 lg:left-60 xl:left-60 -mx-3 md:-mx-9 lg:mx-1 xl:mx-6 font-medium font-Kanit text-2xl md:text-3xl lg:text-3xl xl:text-3xl 
          text-slate-700 hover:text-dms-2textblue cursor-pointer"
            >
              ดูผลคะแนน
            </div>
          </div> */}
        </div>
        {/* รอเปิด */}
        {/* <div
          className="relative mt-6"
         
        >
          <div
            onClick={() => navigate("/Viewscore")}
            
          >
            {" "}
            <img
              src="/images/ปุ่มหน้าหลักสีเทา.png"
              className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-pointer"
            ></img>
            <div
              className="absolute top-2  left-24 md:left-48 lg:left-60 xl:left-60 -mx-3 md:-mx-9 lg:mx-1 xl:mx-6 font-medium font-Kanit text-2xl md:text-3xl lg:text-3xl xl:text-3xl 
          text-slate-700 hover:text-dms-2textblue cursor-pointer"
            >
              ดูผลคะแนน
            </div>
          </div>
        </div> */}
      </div>
      <FunctionLogOut />
    </div>
  );
};

export default Menu;

const ActiveButton = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`${STYLE_RELATIVE_PARENT} cursor-pointer`}
      onClick={() => navigate("/lesson")}
    >
      <div className={STYLE_BUTTON}>
        {/* <img
          src="/images/ปุ่มดาวน์โหลด.png"
          className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-pointer"
        ></img> */}

        <div className={STYLE_TEXT}>เข้าสู่บทเรียน</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
    </div>
  );
};
const InActiveButton = () => {
  return (
    <div className={`${STYLE_RELATIVE_PARENT} cursor-not-allowed `}>
      {/* <img src="/images/ปุ่มหน้าหลักสีเทา.png" className={STYLE_BUTTON}></img> */}
      <div className={STYLE_BUTTON_INACTIVE}>
        <div className={STYLE_TEXT}>เข้าสู่บทเรียน</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
    </div>
    //     <div className="relative mt-6">
    //       <div>
    //         <img
    //           src="/images/ปุ่มหน้าหลักสีเทา.png"
    //           className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-pointer"
    //         ></img>
    //         <div
    //           className="absolute top-2 left-24 md:left-40 lg:left-60 xl:left-64 -mx-4 md:-mx-3 lg:-mx-1 xl:-mx-1  font-medium font-Kanit text-2xl md:text-3xl lg:text-3xl xl:text-3xl
    // text-slate-700 hover:text-dms-2textblue cursor-pointer"
    //         >
    //           เข้าสู่บทเรียน
    //         </div>
    //       </div>
    //     </div>
  );
};

const ActiveButtonPostTest = ({ user }) => {
  const navigate = useNavigate();

  if (
    checkVideoList.every((checkVideoItem) => {
      return user.video_list.includes(checkVideoItem);
    }) &&
    user?.video_list.length >= 9
  ) {
    return (
      <div
        className={`${STYLE_RELATIVE_PARENT} cursor-pointer`}
        onClick={() => navigate("/test")}
      >
        <div className={STYLE_BUTTON}>
          <div className={STYLE_TEXT}>Post-Test</div>
        </div>
        <div className={STYLE_BUTTON2}>
          <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
        </div>
        {/* <img src="/images/ปุ่มดาวน์โหลด.png" className={STYLE_BUTTON}></img> */}
      </div>
    );
  }
  return <InActiveButtonPostTest />;
};
const InActiveButtonPostTest = () => {
  return (
    <div className={`${STYLE_RELATIVE_PARENT} cursor-not-allowed `}>
      {/* <img src="/images/ปุ่มหน้าหลักสีเทา.png" className={STYLE_BUTTON}></img> */}
      <div className={STYLE_BUTTON_INACTIVE}>
        <div className={STYLE_TEXT}>Post-Test</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
    </div>
  );
};

const ActiveButtonViewscore = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`${STYLE_RELATIVE_PARENT} cursor-pointer`}
      onClick={() => navigate("/Viewscore")}
    >
      <div className={STYLE_BUTTON}>
        <div className={STYLE_TEXT}>ดูผลคะแนน</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
      {/* <img src="/images/ปุ่มดาวน์โหลด.png" className={STYLE_BUTTON}></img> */}
      {/* <div className={STYLE_TEXT}>ดูผลคะแนน</div> */}
    </div>
  );
};
const InActiveButtonViewscore = () => {
  return (
    <div className={`${STYLE_RELATIVE_PARENT} cursor-not-allowed `}>
      {/* <img src="/images/ปุ่มหน้าหลักสีเทา.png" className={STYLE_BUTTON}></img> */}
      <div className={STYLE_BUTTON_INACTIVE}>
        <div className={STYLE_TEXT}>ดูผลคะแนน</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
    </div>
    //     <div className="relative mt-6">
    //       <div>
    //         <img
    //           src="/images/ปุ่มหน้าหลักสีเทา.png"
    //           className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-pointer"
    //         ></img>
    //         <div
    //           className="absolute top-2 left-24 md:left-40 lg:left-60 xl:left-64 -mx-4 md:-mx-3 lg:-mx-1 xl:-mx-1  font-medium font-Kanit text-2xl md:text-3xl lg:text-3xl xl:text-3xl
    // text-slate-700 hover:text-dms-2textblue cursor-pointer"
    //         >
    //           ดูผลคะแนน
    //         </div>
    //       </div>
    //     </div>
  );
};

const ActiveButtonPreTest = () => {
  const navigate = useNavigate();

  return (
    <div
      className={`${STYLE_RELATIVE_PARENT} cursor-pointer`}
      onClick={() => navigate("/test")}
    >
      <div className={STYLE_BUTTON}>
        {/* <img
          src="/images/ปุ่มดาวน์โหลด.png"
          className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-pointer"
        ></img> */}
        <div className={STYLE_TEXT}>Pre-Test</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
    </div>
  );
};
const InActiveButtonPreTest = () => {
  return (
    <div className={`${STYLE_RELATIVE_PARENT} cursor-not-allowed `}>
      {/* <img src="/images/ปุ่มหน้าหลักสีเทา.png" className={STYLE_BUTTON}></img> */}
      <div className={STYLE_BUTTON_INACTIVE}>
        <div className={STYLE_TEXT}>Pre-Test</div>
      </div>
      <div className={STYLE_BUTTON2}>
        <div className={` text-2xl md:text-3xl text-[#15496E] `}>.</div>
      </div>
    </div>
    //     <div className="relative mt-6">
    //       <div>
    //         <img
    //           src="/images/ปุ่มหน้าหลักสีเทา.png"
    //           className="w-52 md:w-64 h-14 md:h-16 mx-auto cursor-not-allowed"
    //         ></img>
    //         <div
    //           className="absolute top-2 left-24 md:left-40 lg:left-60 xl:left-72 mx-0 md:mx-3 lg:mx-4 xl:-mx-2  font-medium font-Kanit text-2xl md:text-3xl lg:text-3xl xl:text-3xl
    // text-slate-700 hover:text-dms-2textblue cursor-not-allowed"
    //         >
    //           Pre-Test
    //         </div>
    //       </div>
    //     </div>
  );
};
