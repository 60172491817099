import { useEffect, useState } from "react";
import { BASE_PAGE } from "../../utils/constant";
import api from "../../api";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Certificate from "../../components/pdf/Certificate";
import { SystemStoreUser } from "../../systems/Systems.StorageUser";
import { STYLE_BUTTON, STYLE_BUTTON2, checkVideoList } from "../Menu";
import FunctionHome from "../../utils/home";

const Viewscore = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [Profile, setProfile] = useState([]);

  const user = SystemStoreUser((state) => state.user);
  const largestPointPost = user?.score_post_test;

  console.log(`Profile: `, Profile);

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    setTitle("บทเรียนที่ ");
  }, []);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/users/${user?._id}`);
      setProfile(data?.user);
    } catch (error) {
      console.log("ERROR TO LOAD Profile :", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        // marginBottom: "-60px",
      }}
      className={BASE_PAGE}
    >
      <FunctionHome />
      <div className=" flex justify-center py-0">
        <div className="bg-white  border-2 border-dms-2purplelight  rounded-xl  w-full md:w-11/12 lg:w-11/12 xl:w-7/12  h-full cursor-pointer  z-10">
          <div className=" p-10 px-4 md:px-10 pt-4">
            <div className="text-3xl  text-dms-2textblue font-Kanit font-medium  text-left ">
              ผลคะแนน
            </div>
            <table class="  text-dms-2textblue font-Kanit font-medium border-dms-2purpleintensive mx-auto border-4 rounded-full w-full h-28 text-xl text-center mt-2">
              <thead>
                <tr>
                  <th class="border-4  border-dms-2purpleintensive p-2">
                    Pre-Test
                  </th>
                  <th class="border-4 border-dms-2purpleintensive p-2">
                    {" "}
                    Post-Test
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="border-4 border-dms-2purpleintensive">
                    {Profile?.score_pre_test} คะแนน
                  </td>
                  <td class="border-4 border-dms-2purpleintensive">
                    {checkVideoList.every((checkVideoItem) => {
                      return user.video_list.includes(checkVideoItem);
                    }) && user?.video_list.length >= 9 || user?.generation !== "รุ่นที่ 3"
                      ? Profile?.score_post_test
                      : "-"}{" "}
                    คะแนน
                  </td>
                </tr>
              </tbody>
            </table>
            <div>
              <div
                // onClick={() => navigate("")}
                className="relative mt-4 cursor-pointer "
              >
                <div>
                  <div>
                    {largestPointPost > 9 && (
                      <div className="relative">
                        <PDFDownloadLink document={<Certificate user={user} />}>
                          <div
                            className={`w-full md:w-64 py-3  bg-[#FAEA48] rounded-full mx-auto `}
                          >
                            {/* <img
                              src="/images/ปุ่มดาวน์โหลด.png"
                              className="w-52 md:w-64 h-12 md:h-12 mx-auto"
                            ></img> */}

                            <div
                              className=" font-semibold text-base md:text-lg lg:text-xl xl:text-xl 
                              text-[#15496E] hover:text-dms-bluetext"
                            >
                              ดาวน์โหลดประกาศนียบัตร
                            </div>
                          </div>
                          <div
                            className={`w-full md:w-64 py-3 bg-[#125B7F] text-[#125B7F] rounded-full mx-auto absolute top-[10%] left-[51%] -translate-x-1/2 -z-10`}
                          >
                            <div
                              className={` font-semibold text-base md:text-lg lg:text-xl xl:text-xl 
                              text-[#15496E] hover:text-dms-bluetext`}
                            >
                              .
                            </div>
                          </div>
                        </PDFDownloadLink>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div onClick={() => setOpen(!open)}>
                <div className=" text-lg text-dms-bluefooter font-medium  mt-5 underline hover:text-dms-2textblue ">
                  เงื่อนไขการเรียนการสอนผ่านอินเตอร์เน็ต (ระบบ e-learning)
                </div>
              </div>
              {open && (
                <div
                  className={`${
                    open ? "bg-opacity-20 z-30" : "bg-opacity-0 z-0 w-0 h-0"
                  } bg-black fixed inset-0 transition-all duration-500 w-full `}
                >
                  <div
                    className={`${
                      open
                        ? " h-fit mt-40 md:w-2/3 xl:w-1/2 opacity-100 bottom-32 transfrom -translate-x-1/2 "
                        : " h-0 w-0 opacity-0 bottom-0  left-1/2 transfrom -translate-x-1/2 "
                    } bg-slate-50 mx-auto p-4 md:p-6 left-1/2 absolute z-40 rounded-xl duration-300 top-0 shadow-xl transfrom w-full 
                   `}
                  >
                    {" "}
                    <div
                      onClick={() => {
                        setOpen(false);
                      }}
                      className=" text-red-500 text-lg text-right"
                    >
                      x
                    </div>
                    <div className=" h-96 overflow-y-scroll rounded-xl opacity-100  md:p-4 ">
                      <div className="text-base text-black  text-center leading-8 font-semibold ">
                        เงื่อนไขการเรียนการสอนผ่านอินเตอร์เน็ต (ระบบ e-learning)
                        <div className="text-left ">
                          <div className="text-black ">
                            1.
                            สามารถเข้าระบบการเรียนการสอนผ่านอินเตอร์เน็ตได้ตลอด
                            24 ชั่วโมง
                          </div>
                          <div className="text-black ">
                            2. สามารถรองรับผู้ใช้งานพร้อมกันได้ไม่น้อยกว่า 500
                            คน
                          </div>
                          <div className="text-black ">
                            3. สามารถเข้าไปเรียนซ้ำได้
                            <span className="text-red-600">
                              โดยไม่จำกัดครั้งที่เข้าเรียน
                            </span>
                          </div>
                          <div className="text-black ">
                            4. มีผลการทำข้อสอบทำข้อสอบก่อนเรียน และหลังเรียน
                          </div>
                          <div className="text-black ">
                            <span className="text-red-600"></span>
                            5.
                            สามารถออกใบประกาศนียบัตรได้ผู้เข้ารับการอบรมต้องมีผลคะแนนหลังเรียน
                            (Post-test){" "}
                            {/* <span className="text-red-600">ตั้งแต่</span> */}
                            ตั้งแต่ร้อยละ 50{" "}
                            <span className="text-red-600">(10 ข้อ)</span>
                          </div>
                          <div className="text-black ">
                            6. ให้เข้าเรียนได้ไม่น้อยกว่า
                            <span className="text-red-600">
                              ร้อยละ 60 ของบทเรียนทั้งหมด (ไม่น้อยกว่า 9 เรื่อง)
                              โดยบังคับเรียน 4 เรื่อง และ
                              สามารถเลือกเรื่องอื่นเรียนได้ตามความสนใจ
                            </span>{" "}
                            ดังนี้
                          </div>
                        </div>
                        <table class="  text-black  font-Kanit  border mx-auto  rounded-full w-full h-28 text-sm text-center mt-2">
                          <thead>
                            <tr className="">
                              <th class="border  border-black ">บทที่ </th>
                              <th class="border  border-black p-2"> เรื่อง</th>
                              <th class="border  border-black p-2"> กำหนด</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border  border-black p-2  w-24">
                                บทเรียนที่ 1
                              </td>
                              <td class="border  border-black p-2 text-left">
                                สถานการณ์ผู้สูงอายุไทย
                                และนโยบายการจัดตั้งคลินิกผู้สูงอายุ{" "}
                              </td>
                              <td class="border  border-black p-2"></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 2
                              </td>
                              <td class="border  border-black p-2 text-left">
                                เกณฑ์มาตรฐานการจัดตั้งคลินิกผู้สูงอายุ
                              </td>
                              <td class="border  border-black p-2 text-red-600">
                                บังคับ
                              </td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 3
                              </td>
                              <td class="border  border-black p-2 text-left">
                                การประเมินสุขภาพผู้สูงอายุ (Comprehensive
                                geriatric assessment-CGA) โดยบุคลากรทาง การแพทย์
                              </td>
                              <td class="border  border-black p-2 text-red-600">
                                บังคับ
                              </td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 4
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Dementia
                                and Mild cognitive impairment
                              </td>
                              <td class="border  border-black p-2 text-red-600">
                                บังคับ
                              </td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 5
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง
                                Depression
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 6
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Insomnia
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 7
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง
                                Falling/Instability and OA
                              </td>
                              <td class="border  border-black p-2 text-red-600">
                                บังคับ
                              </td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 8
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง
                                Nutrition
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 9
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง
                                สุขภาพช่องปาก
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 10
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง Eye
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 11
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง ENT
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 12
                              </td>
                              <td class="border  border-black p-2 text-left">
                                กลุ่มอาการสูงอายุ (geriatric syndrome)
                                และการจัดการในโรงพยาบาลระดับ F-A เรื่อง
                                Incontinence
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 13
                              </td>
                              <td class="border  border-black p-2 text-left">
                                หลักการใช้ยาและการจัดการปัญหา polypharmacy
                                ในผู้สูงอายุ
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 14
                              </td>
                              <td class="border  border-black p-2 text-left">
                                Geriatric ambulatory Emergency
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>

                          <tbody>
                            <tr>
                              <td class="border  border-black p-2 w-24">
                                บทเรียนที่ 15
                              </td>
                              <td class="border  border-black p-2 text-left">
                                อภิปรายเรื่องประสบการณ์การจัดตั้งคลินิกผู้สูงอายุ
                              </td>
                              <td class="border  border-black p-2 "></td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <div className="text-black  mt-2 text-left">
                          7.
                          รวบรวมคำถามของผู้ใช้งานส่งให้สถาบันเพื่อตอบทุกสัปดาห์
                          และนำคำตอบไปใส่ในระบบ
                        </div> */}
                      </div>
                    </div>
                    <div className=" flex gap-2 justify-center itesm-center text-sm pt-4 text-dms-2textblue">
                      <input type="checkbox" className="w-5 h-5" />
                      ไม่แสดงหน้านี้อีก
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-left">
              <div className="text-2xl md:text-3xl  text-dms-2textblue font-Kanit font-medium  mt-10">
                โปรไฟล์
              </div>
              {/* {Profile?.map((user) => {
                return ( */}
              <div className="text-dms-2textblue text-sm md:text-lg font-Kanit font-semibold">
                {" "}
                <div>
                  {user?.prefix} {user?.name_surname}
                </div>
                <div>ตำแหน่ง {user?.job_position}</div>
                <div>งานที่รับผิดชอบ {user?.responsible_work}</div>
                <div className="grid grid-cols-2">
                  <div> {user?.location}</div>
                  <div>ระดับโรงพยาบาล {user?.hospital_level}</div>
                </div>
                <div className="grid grid-cols-2">
                  <div>จังหวัด {user?.province}</div>
                  <div>เขตสุขภาพที่ {user?.county}</div>
                </div>
                <div className="md:grid grid-cols-2">
                  <div>เบอร์โทร {user?.telephone_number}</div>
                  <div>อีเมล {user?.email}</div>
                </div>
              </div>
              {/* );
              })} */}
              <div className="text-dms-2textblue text-sm font-Kanit font-medium pt-4">
                หากต้องการความช่วยเหลือ หรือแก้ไขข้อมูลติดต่อ
                dmsagingthai.team@gmail.com
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewscore;
