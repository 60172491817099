import create from "zustand";
import { persist } from "zustand/middleware";

export const SystemStoreExamData = create(
  persist(
    (set, get) => ({
      examData: null,
      setExamData: (examData) => set(() => ({ examData })),
      clearExamData: () => set(() => ({ examData: null })),
    }),
    {
      name: "examData",
      getStorage: () => sessionStorage,
    }
  )
);
