import { useState } from "react";
import { useLocation } from "react-router-dom";
import { CornerLogo } from "./constant";
import { useIsMobile } from "./ismobile";

const NavbarMobile = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className=" pb-16">
      <div className="bg-dms-2purpleintensive w-full h-16 absolute top-0  ">
        <div className=" p-2 md:p-0">
          <div
            className="flex justify-between text-white text-xs md:text-base lg:text-xl xl:text-xl text-center font-Kanit font-normal 
          mx-10 md:mx-40 lg:mx-full xl:mx-96 pt-4"
          >
            <CornerLogo />
            {/* <div
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 15 15"
                  className="-mr-6 -my-2"
                >
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M11.782 4.032a.575.575 0 1 0-.813-.814L7.5 6.687L4.032 3.218a.575.575 0 0 0-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 0 0 .814.814L7.5 8.313l3.469 3.469a.575.575 0 0 0 .813-.814L8.313 7.5l3.469-3.468Z"
                    clip-rule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 21 21"
                  className="-mr-6 -my-2"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 6.5h12m-12.002 4h11.997M4.5 14.5h11.995"
                  />
                </svg>
              )}
            </div> */}
          </div>
        </div>
        <div className=" flex justify-end items-center  md:-my-8 lg:-my-9 xl:-my-9 p-2 mx-12 md:mx-8 lg:mx-12 xl:mx-12"></div>
      </div>

      {open && (
        <div className="bg-white  w-full h-40 p-2 px-4 text-lg text-dms-bluetext font-Kanit pt-20">
          {" "}
          <div className="">หน้าแรก</div>
          <div className="">เข้าสู่บทเรียน</div>
          <div className="">ดูผลคะแนน</div>
          <div className="">คำถาม-คำตอบ</div>
          <div className=" ">ออกจากระบบ</div>
        </div>
      )}
    </div>
  );
};

export default NavbarMobile;
