import { useNavigate } from "react-router-dom";

export const BASE_PAGE =
  "text-center text-blue-800 text-6xl font-bold bg-dms-2purplee h-full px-4 md:px-10 ";
export const BASE_PAGE_UNMOVE =
  "h-96 text-center text-blue-800 text-6xl font-bold bg-base object-contain";

export const CornerLogo = () => {
  const navigate = useNavigate();
  return (
    <div>
      <img
        src="images/llogodms.png"
        onClick={() => navigate("/menu")}
        className="cursor-pointer absolute top-3 md:top-2 lg:top-2 xl:top-1 left-4 w-10 md:w-12 lg:w-12 xl:w-14 "
      ></img>{" "}
    </div>
  );
};
